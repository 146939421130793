/*------------------------------------*\
    #BUTTONS
\*------------------------------------*/

[role="button"] {
    outline: none;
}

.btn {
    transition: .2s;
    @include font-weight(light);
    @include btn-color($color-btn);
    box-shadow: 0 0 2px rgba(0,0,0,.08),0 2px 4px rgba(0,0,0,.16);
    color: white;
    border-radius: 0;
    outline: none;
    box-shadow: 0 1px 6px 0 rgba(0,0,0,.44);
    padding: 10px 20px;
    border-radius: 50px;
    border: none;
}

// Styling btn-group
.btn-group {
    & > .btn {                     
        &:not(.btn--inactive) {
            color: white;
            border: 1px solid $color-brand; 
        }
    }
}


.btn--secondary {
    background: none;
    color: $color-btn;
    @include attention() {
        background: none;
        color: darken($color-btn, 10%);
    }
    @include attention(active) {
        background: $color-btn;
        color: white;
    }
}
.btn--accent {
    @include btn-color($color-btn-accent);
}
.btn--slim {
    @extend .btn;
    @extend .btn--small;
    font-size: .8em;
    font-weight: normal;
}
.btn--positive {
    @include btn-color($color-btn-positive);
}
.btn--negative {
    @include btn-color($color-btn-negative);
}
.btn--submit {
    @include font-weight(regular);
    @include btn-color($color-btn-submit);
}
.btn--inactive {
    @include btn-inactive($color-btn);
}
.btn--static {
    @include font-weight(regular);
    @include btn-static(#cfcfcf);
}


%btn-social {
    padding: 4px 12px;
}
.btn--facebook {
    @extend %btn-social;
    @include btn-color(#3b5998);
}
.btn--twitter {
    @extend %btn-social;
    @include btn-color(#1b95e0);
}
.btn--google {
    @extend %btn-social;
    @include btn-color(#db4437);
}
.btn--scroll-top {
    display: none;
    width: 48px;
    height: 57px;
    position: fixed;
    bottom: -26px;
    right: $inuit-base-spacing-unit;
    padding: $inuit-base-spacing-unit--small;
    border-radius: 100px;
    text-align: center;
    color: #fff;
    background-color: $color-main;
    box-shadow: 0 0 3px 1px rgba(64, 64, 64, 0.4);
    backface-visibility: hidden; // prevent repaint overhead on scroll event
}



/*------------------------------------*\
    #PILLS
\*------------------------------------*/
.pill {
    display: inline-block;
    &:not(:nth-child(1)) {
        margin-left: $inuit-base-spacing-unit--small;
    }
    & > .pill-height-helper {
        height: 100%;
    }
    & > * {
        vertical-align: middle;
    }
}