///*------------------------------------*\
//    #PROMO-BANNER
//\*------------------------------------*/

.promo-banner {
    margin-bottom: $wrapper-padding-spacing-unit;
}
.promo-crossFading {
    position:relative;
    margin:0 auto;
}
.promo-crossFading img:first-child {
    position: absolute;
    opacity: 0;
}

.promo {
    margin-top: 25px;
}
.promo-title {
    @include font-size(28px);
    font-weight: bold;
    color: $color-title-promo;
}
.promo-app {
    min-height: 80px;
    background-color: rgba(0,0,0,0.1);
    border-radius: 5px;
    padding: 15px 0;
}
.promo-flightImg {
    margin-bottom: 15px;
}