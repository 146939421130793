///*------------------------------------*\
//    #PROMO-FLIGHT
//\*------------------------------------*/

.promo-flight__col-image {
    @include span(1 of 3);
    @include media-query(palm) {
        @include span(full);
    }
}

.promo-flight__col-content {
    @include span(2 of 3);
    @include media-query(palm) {
        @include span(full);
    }
}