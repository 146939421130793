/*Style Radio*/
$darkNavy: #d5d6d6;
$teal1: #66B3FB;
$teal2: #4B9DEA;
$charcoal: #555555;
$gold: #B6985A;
$activeShadow: 0 0 10px rgba($teal1, .5);
@mixin focusOutline {outline: dotted 1px #CCC; outline-offset: .45rem;}
@mixin destroyTag {width: auto; height: auto;text-decoration: none;border: 0}
const lap-and-up{min-width: 45em};
.loader-content {
    display: none;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    opacity: .8;
    background: #ffffffeb;
    .loader-gif{
        display: table-cell;
        margin-top:25%;
        padding: 0 10px;
        vertical-align: middle;
        .gif{
            background: url('../img/loading/loading-agent.gif') 50% 50% no-repeat;
            height: 128px;
            margin-bottom: 18px;
        }
        .text{
            text-align: center;
            font-size: x-large;
        }
    }
}


.toggle-radio {
    margin: 0;
    box-sizing: border-box;
	font-size: 70%;
    display: flex; 
    flex-flow: row nowrap;
    justify-content: flex-end; 
    align-items: stretch;
    a{
        @include destroyTag;
        color: rgb(0, 0, 0.52);
        position: relative;
        text-align: center;
        margin: 2%;
        &:hover,&:active{
            @include destroyTag;
            color: rgb(0, 0, 0.76);
        }
    }
}

.divformQse{
    display:block;
    width:100%;
    margin: 2% 0 2% 0;
}
.divformQse .row {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    margin: 0;
    margin-bottom: 1%;
    padding-bottom: 1%;
}
.divformQse .row:hover {
    box-shadow: 2px 4px 20px 4px rgba(0,0,0,0.29);
}
.slidecontainer {
     width: 100%;
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 30px;
    border-radius: 15px;
    background: #d4d7d4;
    outline: none;
    /*opacity: 0.7;*/
    -webkit-transition: .2s;
    transition: opacity .2s;
    padding: 0 1% 0 1%;
}
.slider:disabled {
    -webkit-appearance: none;
    width: 100%;
    height: 30px;
    border-radius: 15px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
    padding: 0 1% 0 1%;
}

.slider:hover {
    opacity: 1;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #008cf3;
    cursor: pointer;
}

.slider:disabled::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #545654;    
    cursor: not-allowed;
}

.slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #4CAF50;
    cursor: pointer;
}

.labelSlider{
    position:relative;
    font-size: 12px;
}

.slidecontainer div {
    display: block;
    width: 100%;
}
.slidecontainer div span {
    width: 32%;
    display: inline-table;
    font-size: 60%;
}
.slidecontainer div span label {
    width: auto;
    display: inline-table;
    text-align: center;
}
.right{
    text-align:right;
}
.center{
    text-align:center;
}

/*checkbox*/
.switch2 {
position: relative;
display: inline-block;
margin: 0 5px;
}

.switch2 > span {
position: absolute;
top:11px;
pointer-events: none;
font-family: 'Helvetica', Arial, sans-serif;
font-weight: bold;
font-size: 12px;
text-transform: uppercase;
text-shadow: 0 1px 0 rgba(0, 0, 0, .06);
width: 50%;
text-align: center;
}

input.check-toggle-round-flat:checked ~ .off {
color: #fff;
}

input.check-toggle-round-flat:checked ~ .on {
color: #fff;
}

.switch2 > span.on {
left: 0;
padding-left: 2px;
color: #fff;
}

.switch2 > span.off {
right: 0;
padding-right: 4px;
color: #fff;
}

.check-toggle {
position: absolute;
margin-left: -9999px;
visibility: hidden;
}
.check-toggle + label {
display: block;
position: relative;
cursor: pointer;
outline: none;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
}

input.check-toggle-round-flat + label {
padding: 2px;
width: 97px;
height: 34px;
background-color:#d4d7d4;
-webkit-border-radius: 60px;
-moz-border-radius: 60px;
-ms-border-radius: 60px;
-o-border-radius: 60px;
border-radius: 60px;
}
input.check-toggle-round-flat + label:before, input.check-toggle-round-flat + label:after {
display: block;
position: absolute;
content: "";
}

input.check-toggle-round-flat + label:before {
top: 2px;
left: 2px;
bottom: 2px;
right: 2px;
background-color: #d4d7d4; 
border-radius: 60px;
}
input.check-toggle-round-flat + label:after {
top: 4px;
left: 4px;
bottom: 4px;
width: 46px;
background-color: #46a31d;
-webkit-border-radius: 52px;
-moz-border-radius: 52px;
-ms-border-radius: 52px;
-o-border-radius: 52px;
border-radius: 52px;
-webkit-transition: margin 0.2s;
-moz-transition: margin 0.2s;
-o-transition: margin 0.2s;
transition: margin 0.2s;
}

input.check-toggle-round-flat:checked + label:after {
margin-left: 44px;
background: #008cf3;
}
#widthCheck {width: 157px;height: 34px;}
#widthCheck:after {width: 75px;margin-left: 75px;}
input.check-toggle-round-flat:checked + #widthCheck:after {margin-left: 0px;}
#letterSize{font-size: 65%;padding: 2px 0 0 7px;}
#on{font-size: 65%;padding: 2px 0 0 7px;}
#off{font-size: 65%;padding: 2px 0 0 0px;}
#widthCheck2 {width: 62px;height: 34px;}
#widthCheck2:after {width: 27px;}
input.check-toggle-round-flat:checked + #widthCheck2:after{margin-left: 28px;}
input.check-toggle-round-flat:disabled + #widthCheck2:after,input.check-toggle-round-flat:disabled + #widthCheck2:before{background-color: #d4d7d4;}
@media (max-width:1099px){
    .card { 
    width:45%;
        margin: 0 2.5% 30px;
}
}
@media (max-width:499px){
    .card { 
    width:95%; 
}
}

.viewInfoCustomer {
    text-decoration:  none;
    color:  black;
    font-weight: bold;
    display: flex;
}

.viewInfoCustomer > span {
    /* font-size: 17px; */
    color: #afb3ae;
    margin: 0 5% 0 5%;
}

.viewInfoCustomer:hover {
    text-decoration:  none;
    color: #000;
    /* font-weight:  bold; */
}

.viewInfoCustomer:hover >span {
    color: #23527c;
    font-weight: bold;
    font-size: 17px;
}

/* Tooltip container */
.tooltipClass {
    position: relative;
    display: inline-block;
    opacity: 1;
}

/* Tooltip text */
.tooltipClass .tooltiptext {
    visibility: hidden;
    width: auto;
    background-color: #000;
    color: #fff;
    text-align: left;
    padding: 6px;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 140%;
}

.tooltiptext > small {
    display: inline-table;
    width: max-content;
}

/* Tooltip arrow */
.tooltipClass .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #000 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltipClass:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.alert-error-config-qse{
    display: none;
    position: absolute;
    right: 0;
    width: 315px;
    height: auto;
    background: #fbc337;
    margin: 2px 7% 0 0;
    border: 1px #ffb600 solid;
    border-radius: 3px;
    text-align: center;
    padding: 2px;
    -webkit-animation: flash-config-qse linear 1s infinite;
    animation: flash-config-qse linear 1s infinite;
    #text-error-config {
        color: #5d4800;
        font-size: 12px;
        padding: 0;
        margin: 0;
        text-decoration: none;
    }
	span{
		margin: 0 6px 0 0;
	}
}
@-webkit-keyframes flash-config-qse {
    0% { opacity: 1; } 
    50% { opacity: 0.9; box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.32), 0 1px 5px 0 rgba(0, 0, 0, 0.42), 0 3px 1px -2px rgba(0, 0, 0, 0.32); background-color: rgb(251, 195, 55);border-color: rgb(251, 195, 55);} 
    100% { opacity: 1; }
}
@keyframes flash-config-qse {
    0% { opacity: 1; } 
    50% { opacity: 0.9; box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.32), 0 1px 5px 0 rgba(0, 0, 0, 0.42), 0 3px 1px -2px rgba(0, 0, 0, 0.32); background-color: rgb(251, 195, 55); border-color: rgb(251, 195, 55); } 
    100% { opacity: 1; }
}
/*Home Octopus*/
.cont_header {
    display: table;
    width: 100%;
    /* background: #000; */
    padding: 5px 0 0 0;
    height: 10%;
}

.cont_img_logo {
    display: table-row;
    float: left;
    width: 35%;
    height: 100%;
    /* background: #ffff; */
}

.cont_info {
    display: table;
    float: right;
    width: 60%;
    height: 100%;
    /* background: #ca2c2c; */
}

.header_cont__loggin_call {
    display: table-row;
    width: 100%;
    height: 70%;
    background:transparent;
}

.header__call {
    float: left;
    width: 30%;
    height: 100%;
    display:none;
    @media screen and (min-width: 1190px) {
        display: table-row;
    }
}

.header__login {
    display: flex;
    float: right;
    width: auto;
    height: 100%;
    align-items: flex-end;
}

.header_cont__option {
    display: table-row;
    width: 100%;
    height: 20%;
}

.cont_header__login {
    position: absolute;
    display: contents;
    bottom: 0;
}