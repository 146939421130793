///*------------------------------------*\
//    #NAV-TOP
//\*------------------------------------*/


// Internal variables
$nav-top-font-size: 16px;
$nav-top-font-size--large: 20px;

.nav-top__login, .nav-top__logged  {
    font-weight: 600;
    text-align: center;
    text-shadow: 1px 1px 3px rgba(0,0,0,.5);
}

.nav-top__login {
    @include inuit-font-size($nav-top-font-size);
    @include media-query(lap-and-up) {
        line-height: 1.5;
        font-size: 0.8rem;
    }
}

.nav-top ul li * {
    display: block
}
