/*------------------------------------*\
    #PRINT
\*------------------------------------*/

@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
}  

.only-print{
    @media screen  {
        display: none;
    }
    @media print
    {     
        display: block;
    }
} 