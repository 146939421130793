.page-foot__us__simple {
    @include span(1 of 4);
    @include media-query(portable) {
        @include span(1 of 3);
    }
}
.page-foot_link-social {
    background-color: $color-main !important;
}
.page-foot__content-social {
    justify-content: center;
}
