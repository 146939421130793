///*------------------------------------*\
//   #DISPO-HOTEL
//\*------------------------------------*/
// Internal variable
$height-big:     184px;
$height-small:   140px;

.dispo-hotel__rating-title {
    float: left;
    display: inline-block;
    padding: $inuit-base-spacing-unit 0;
    margin-right: $inuit-base-spacing-unit;
    @include media-query(palm){
        margin-right: $inuit-base-spacing-unit--tiny;
    }
}
.dispo-hotel__rating-stars {
    float: left;
    display: inline-block;
    background: url(/assets/common_assets/img/icon/star_off.svg);
    background-size: contain;
    display: inline-block;
    font-size: 0;
    height: 45px;
    overflow: hidden;
    position: relative;
    width: 225px;
    white-space: nowrap;
    @include media-query(palm){
        margin-bottom: $inuit-base-spacing-unit;
    }
}
/*.starRating-title {
    color: $color-main;
    padding: 10px 0 0 15px;
    margin: 0;
}*/

.dispo-hotel__rating-label {
    margin: 0;
    color: $color-main;
    font-size: 13px;
    background: none;
    height: 100%;
    left: 0;
    line-height: 48px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 20%;
    z-index:  $z-index-hotel-rating-label;
}
.dispo-hotel__rating-input {
    -moz-appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    display: inline-block;
    height: 100%;
    margin: 0 !important;
    opacity: 0;
    padding: 0;
    position: relative;
    width: 20%;
    z-index: $z-index-hotel-rating-input;

}
.dispo-hotel__rating-input:hover, .dispo-hotel__rating-input:checked {
    margin: 0 !important;
}
.dispo-hotel__rating-input:checked + .dispo-hotel__rating-label {
    background: url(/assets/common_assets/img/icon/star_on.svg);
    background-size: contain;
}
.dispo-hotel__rating-input:hover + .dispo-hotel__rating-label {
    opacity: .5;
}
.dispo-hotel__rating-input:active + .dispo-hotel__rating-label {
    opacity: 1;
}
.dispo-hotel__rating-stars .dispo-hotel__rating-label ~ .dispo-hotel__rating-label {
    left: 20%;
}
.dispo-hotel__rating-stars .dispo-hotel__rating-label ~ .dispo-hotel__rating-label ~ .dispo-hotel__rating-label {
    left: 40%;
}
.dispo-hotel__rating-stars .dispo-hotel__rating-label ~ .dispo-hotel__rating-label ~ .dispo-hotel__rating-label ~ .dispo-hotel__rating-label {
    left: 60%;
}
.dispo-hotel__rating-stars .dispo-hotel__rating-label ~ .dispo-hotel__rating-label ~ .dispo-hotel__rating-label ~ .dispo-hotel__rating-label ~ .dispo-hotel__rating-label {
    left: 80%;
}
/*@media screen and (min-width: 750px) {
    .starRating-title {
        padding-top: 15px;
    }
}*/



.frame-group .frame.recommendation {
    margin-bottom: $inuit-base-spacing-unit;
}

.dispo-hotel__left-column {
    @include span(2 of 3);
    @include media-query(palm){
        float: none;
    }
}


.dispo-hotel__title {
    margin-bottom: 0;
    @include font-weight(bold);
    @include media-query(palm){
        width: 100%;
        margin-bottom: $inuit-base-spacing-unit--small;
        text-align: center;
        @include font-size(18px);
    }
}

.dispo-hotel__submit {
    @include span(1 of 3);
    @include media-query(lap-and-up){
        margin: $inuit-base-spacing-unit--small 0;
    }
    @include media-query(palm){
        @include span(full);
    }
}
.dispo-hotel__show-map {
    float: left;
    display: inline-block;
    font-weight: 600;
    text-align: center;
    padding: $inuit-base-spacing-unit--small;
    @include media-query(lap-and-up){
        float: right;
        margin-bottom: $inuit-base-spacing-unit--small;
    }
}

/* Map -> Price filter */
.dispo-hotel__map-container .map__price-filter {
    position: absolute;
    left: 0;
    display: inline-block;
    visibility: hidden;
    @include media-query(palm) {
        bottom: 0;
        width: 100%;
    }
    @include media-query(lap-and-up) {
        top: $inuit-base-spacing-unit--small;
        left: $inuit-base-spacing-unit--small;
    }
}
.dispo-hotel__map-container .map__price-filter > * {
    z-index: 5;
}
.dispo-hotel__map-container .map__price-filter .map__price-range {
    position: relative;
    font-weight: 600;
    padding: $inuit-base-spacing-unit--small;
    background-color: #fbfbfb;
    border: 1px solid rgba(0, 0, 0, .3);
    border-radius: 2px;
    @include media-query(palm) {
        padding-top: round($inuit-base-spacing-unit * 2);
    }
    @include media-query(lap-and-up) {
        margin-bottom: 3px;
        border-color: $color-frame-border;
        box-shadow: 0 1px 4px -1px rgba(0, 0, 0, .3);
    }
}
.dispo-hotel__map-container .map__price-filter .map__price-range .map__price-label {
    font-size: 13px;
}
.dispo-hotel__map-container .map__price-filter .map__price-range .map__price-amount {
    color: $color-dispo-filter;
    font-size: $inuit-base-font-size;
    font-weight: 600;
    margin-left: $inuit-base-spacing-unit--small;
}
.dispo-hotel__map-container .map__price-filter .map__slider-range {
    display: inline-block;
    &.ui-slider-horizontal { // 'palm'
        position: absolute;
        top: 0;
        left: 5%;
        width: 90%;
        margin-top: $inuit-base-spacing-unit;
        & .ui-slider-range {
            border-color: rgba(0,0,0,.2);
        }
        & .ui-slider-handle {
            width: 1.5em;
            margin-left: -.75em;
            border-color: rgba(0,0,0,.3);
        }
    }
    &.ui-slider-vertical { // 'lap-and-up'
        float: left;
        width: 1em;
        height: 250px;
        left: round($inuit-base-spacing-unit--small * 3 - 5);
        box-shadow: 0 1px 4px -1px rgba(0, 0, 0, .3);
        & .ui-slider-handle {
            width: 1.5em;
            border-color: #c6c6c6;
        }
    }
}

/* Map -> Price InfoBox */
.dispo-hotel__map-container #map .ib_hotel {
    position: relative;
}
.dispo-hotel__map-container #map .ib_pointer {
    position: absolute;
    bottom: -29px;
    width: 5px;
    height: 6px;
    overflow: hidden;
    z-index: 2;
    &.left-side {
        left: 15px;
    }
    &.left-side > * {
        transform: skewX(45deg);
        transform-origin: 0 0 0;
        border-left: 1px solid $color-frame-border;
    }
    &.right-side {
        left: 20px;
    }
    &.right-side > * {
        transform: skewX(-45deg);
        transform-origin: 5px 0 0;
        border-right: 1px solid $color-frame-border;
    }
    &.left-side > *,
    &.right-side > * {
        width: inherit;
        height: inherit;
        background-color: #fbfbfb;
        box-shadow: 0 1px 3px rgba(0,0,0,.6);
    }
}
.dispo-hotel__map-container #map .ib_hotel-price {
    position: absolute;
    padding: 5px;
    border: 1px solid $color-frame-border;
    border-radius: 2px;
    background-color: #fbfbfb;
    box-shadow: 0 1px 2px -1px rgba(0,0,0,.6);
}

/* Map -> Rotating Marker */
.dispo-hotel__map-container #map .rotate-marker {
    position: absolute;
    width: 26px;
    height: 38px;
    cursor: pointer;
    background: url(/assets/aviatur_assets/img/icon/marker-point.svg) no-repeat center;
    animation: rotate-y 2s infinite;
}

/* Map -> Hotel InfoWindow */
.dispo-hotel__map-container #map .iw_hotel {
    max-width: 255px;
}
.dispo-hotel__map-container #map .iw_hotel,
.dispo-hotel__map-container #map .ib_hotel-price {
    font-family: 'Open Sans';
}
.dispo-hotel__map-container #map .iw_hotel > *:not(:last-child) {
    margin-bottom: $inuit-base-spacing-unit--small;
}
.dispo-hotel__map-container #map .iw_hotel-name {
    font-weight: 600;
}
.dispo-hotel__map-container #map .iw_hotel-stars {
    display: block;
    margin: 0 auto;
}
.dispo-hotel__map-container #map .iw_hotel-img {
    max-width: 255px;
}
.dispo-hotel__map-container #map .iw_hotel-price span.float-left {
    float: left;
    margin-right: $inuit-base-spacing-unit--small;
}