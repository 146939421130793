/*------------------------------------*\
    #BUTTONS
\*------------------------------------*/

.frame-group {
    width: 100%;
}

%frame-element {
    //background-color: rgba(255,255,255,.04);
    border: 0 solid transparent;
    border-radius: 5px;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.3);
}

.frame,
.frame-single {
    margin-bottom: 10px;
    @extend %frame-element;
    padding:10px 20px;
    position: relative;
    background:$color-frame-hero-type;
    @include media-query(palm) {
        padding-top: $inuit-base-spacing-unit--small;
    }
}

.frame {
    margin-left:0!important;
}

.frame-pdf, .frame-single-pdf {
    @extend %frame-element;
    padding: $inuit-base-spacing-unit $inuit-base-spacing-unit 0 $inuit-base-spacing-unit;
    position: relative;
}
// middle with dinamically hidden sibling
.frame.js-hidden:not(:first-of-type) + .frame {
    border-top-color: transparent;
    border-bottom: 1px dashed $color-frame-border;    
}
// hidden frame is first element
.frame.js-hidden:first-of-type + .frame {
    border-bottom: 1px dashed $color-frame-border;
}

//last
.frame-group > div:last-of-type:not(:first-of-type)  {  
    margin-top: -1px;
    border-top-color: transparent;
    border-bottom: 1px solid $color-frame-border;
}

.frame--hero {
    color: $color-frame-hero-type;
    background-color: $color-frame-hero;
    border: 1px solid lighten($color-frame-hero, 15%) !important; 
}

.frame--light {
    @extend .sp-vinset;
    border-top: 1px solid $color-frame-border;
    border-bottom: 1px solid $color-frame-border;
}

.frame--coupon {
    background-color: #c81111;
    padding-bottom: $inuit-base-spacing-unit;
    @include media-query(palm) {
        padding-bottom: $inuit-base-spacing-unit--small;
    }
}

.frame--negative {
    color: $color-frame-hero-type;
    background-color: $color-frame-negative;
    border: 1px solid lighten($color-frame-negative, 15%);
}

.frame--gradient {
    color: $color-frame-hero-type;
    @include blueGradient();
    border: 1px solid lighten($color-frame-hero, 15%);
}

.frame--break {
    background-color: lighten($color-frame-hero, 47%)
}
.frame--hero, .frame--negative,.frame--gradient, .frame--break {
    margin-top: -1px;
}
#AV_widget_iframe{
    border: none;
}