/*------------------------------------*\
    #FORMS
\*------------------------------------*/
/**
 * A simple form object.
 */

// Predefine the variables below in order to alter and enable specific features.
$inuit-form-namespace:       $inuit-namespace !default;

$inuit-form-padding:         halve($inuit-base-spacing-unit) !default;
$inuit-form-padding--small:  halve($inuit-form-padding) !default;
$inuit-form-padding--large:  double($inuit-form-padding) !default;
$inuit-form-background:      white !default;
$inuit-form-color:           #000 !default;
$inuit-form-border-width:    1px !default;
$inuit-form-border-style:    solid !default;
$inuit-form-border-color:    $color-form-border !default;
$inuit-form-radius:          0 !default;

$inuit-enable-text-input--small:   false !default;
$inuit-enable-text-input--large:   false !default;
$inuit-enable-text-input--full:    false !default;
$inuit-enable-text-input--pill:    false !default;





// Here we set a variable assuming that `box-sizing: border-box;` is not set
// globally. If it has been previously been defined, the following variable will
// be overriden and will be set to `true`.
$inuit-global-border-box: false !default;





/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 * 6. Fixes odd inner spacing in IE7.
 * 7. Subtract the border size from the padding value so that buttons do not
 *    grow larger as we add borders.
 */
.#{$inuit-form-namespace}text-input,
%#{$inuit-form-namespace}text-input {
    //background-color: $inuit-form-background;
    display: inline-block; /* [1] */
    vertical-align: middle; /* [2] */
    font: inherit; /* [3] */
    font-size:1rem;
    border: none; /* [4] */
    margin:  0; /* [4] */
    cursor: pointer; /* [5] */
    overflow: visible; /* [6] */
    padding:   0 $inuit-form-padding - $inuit-form-border-width; /* [7] */
    //height: double($inuit-base-spacing-unit);
    //line-height: double($inuit-base-spacing-unit);
    width: 100%;
    outline: none;

    background-color: #eee;
    //border: 0px solid #d8d8d8;
    padding-top: 5px;
    padding-bottom: 5px;
    height: 40px;
    border-radius: 2px;
    color: #444;

    //@if($inuit-form-border-width != 0) {
        //border: $inuit-form-border-width $inuit-form-border-style $inuit-form-border-color;
    //}

    //@if($inuit-form-radius != 0) {
        //border-radius: $inuit-form-radius;
    //}

    &,
    &:hover,
    &:active,
    &:focus {
        text-decoration: none; /* [4] */
        color: $inuit-form-color;
    }

}





@if ($inuit-enable-text-input--small == true) {

    /**
     * Small buttons.
     */

    .#{$inuit-form-namespace}text-input--small,
    %#{$inuit-form-namespace}text-input--small {
        padding: 0  ($inuit-form-padding--small - $inuit-form-border-width); /* [7] */
        height: $inuit-base-spacing-unit + halve($inuit-base-spacing-unit);
        line-height: $inuit-base-spacing-unit + halve($inuit-base-spacing-unit);
    }

}

@if ($inuit-enable-text-input--large == true) {

    /**
     * Large buttons.
     */

    .#{$inuit-form-namespace}text-input--large,
    %#{$inuit-form-namespace}text-input--large {
        padding: 0  ($inuit-form-padding--large - $inuit-form-border-width); /* [7] */
        height: double($inuit-base-spacing-unit) + $inuit-base-spacing-unit;
        line-height: double($inuit-base-spacing-unit) + $inuit-base-spacing-unit;
    }

}





@if ($inuit-enable-text-input--full == true) {

    /**
     * Full-width buttons.
     */

    .#{$inuit-form-namespace}text-input--full,
    %#{$inuit-form-namespace}text-input--full {
        width: 100%;

        @if $inuit-global-border-box == false {
            /**
             * Remove paddings so that widths and paddings don’t conflict.
             */
            padding-right: 0;
            padding-left:  0;
        }

    }

}





@if ($inuit-enable-text-input--pill == true) {

    /**
     * Round-ended buttons.
     *
     * 1. Overly-large value to ensure the radius rounds the whole end of the
     *    button.
     */

    .#{$inuit-form-namespace}text-input--pill,
    %#{$inuit-form-namespace}text-input--pill {
        border-radius: 100px; /* [1] */
    }

}
.#{$inuit-form-namespace}text-input-flight,
%#{$inuit-form-namespace}text-input-flight {
    background-color: transparent;
    display: inline-block; /* [1] */
    vertical-align: middle; /* [2] */
    font: inherit; /* [3] */
    font-size:1rem;
    border: none; /* [4] */
    margin:  0; /* [4] */
    cursor: pointer; /* [5] */
    overflow: visible; /* [6] */
    padding: 0.625rem; /* [7] */
    padding-right: 1.5rem;
    width: 100%;
    outline: none;
    height: 2.5rem;
    border-radius: 2px;
    background-color: #eee;
    color: #444;
    font-family: sans-serif;
    text-overflow: ellipsis;

    &,
    &:hover,
    &:active,
    &:focus {
        text-decoration: none; /* [4] */
        color: #444;
        font-family: sans-serif;
        font-weight: 400;
        @media (max-width: 991px) {
            color: #444;
            padding-right: 1.5rem;
        }
    }

}

input.text-input-flight::placeholder {
    color: #e1e1e1;
    @media (max-width: 991px) {
        color: #8b8b8b;
    }
}
