/*------------------------------------*\
    #LABEL
\*------------------------------------*/

label {
    color: $color-label;
    min-height: 1.5em;
    @include font-size(14px);
    @include font-weight(300);
    transition: .2s;    
    @include attention() {
        color: $color-label-hover;
        cursor: pointer;
    }  
}