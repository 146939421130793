        .plotLegend{
            margin-top: 10px;
        }
        .plot{
            background-color: #FF4717;
        }

        .mapcontainer {
            width: 100%;
            overflow: hidden;
            min-width: 700px;
            max-width: 1400px;
            margin-left: auto;
            margin-right: auto;
        }

        .knobContainer {
            text-align: center;
            margin: 10px;
        }

        .knobContainer canvas {
            cursor: pointer;
        }

        .rightPanel {
            float: right;
            width: 223px;
            border-radius: 5px;
            margin-left: 5px;
        }

        /* Specific mapael css class are below
         * 'mapael' class is added by plugin
        */

        /* On screens that are 600px wide or less, make the columns stack on top of each other instead of next to each other */
        @media screen and (min-width:670px) and (max-width: 750px) {
            svg {
                width: 800px;
            }
            .map {
                margin-right: 90px;
                /*margin-bottom: -100px;*/
            }
        }

        @media screen and (min-width:590px) and (max-width: 669px) {
            svg {
                width: 520px;
            }
            .map {
                margin-right: 170px;
                /*margin-bottom: -100px;*/
            }
        }

        @media screen and (min-width:471px) and (max-width: 589px) {
            svg {
                width: 400px;
            }
            .map {
                margin-right: 280px;
                /*margin-bottom: -100px;*/
            }
        }

        @media screen and (min-width:300px) and (max-width: 470px) {
            svg {
                width: 350px;
            }
            .map {
                margin-right: 350px;
                /*margin-bottom: -100px;*/
            }
        }

        .mapael .mapTooltip {
            position: absolute;
            background-color: #fff;
            moz-opacity: 0.80;
            opacity: 0.80;
            filter: alpha(opacity=80);
            border-radius: 4px;
            padding: 10px;
            z-index: 1000;
            max-width: 200px;
            display: none;
            color: #232323;
        }

        .mapael .map {
            /*margin-right: 228px;*/
            overflow: hidden;
            position: relative;
            background-color: #0063BD;
            border-radius: 5px;
        }

        /* For all zoom buttons */
        .mapael .zoomButton {
            background-color: #fff;
            border: 1px solid #ccc;
            color: #000;
            width: 15px;
            height: 15px;
            line-height: 15px;
            text-align: center;
            border-radius: 3px;
            cursor: pointer;
            position: absolute;
            top: 0;
            font-weight: bold;
            left: 10px;

            -webkit-user-select: none;
            -khtml-user-select : none;
            -moz-user-select: none;
            -o-user-select : none;
            user-select: none;
        }

        /* Reset Zoom button first */
        .mapael .zoomReset {
            top: 10px;
        }

        /* Then Zoom In button */
        .mapael .zoomIn {
            top: 30px;
        }

        /* Then Zoom Out button */
        .mapael .zoomOut {
            top: 50px;
        }

        .europa_mundo {
            r: 3px;
        }

/* LO QUE YA ESTABA */
        .panel-body a img {
            width: 80%;
            padding: 5%;
        }
        .imagen {
            width: 100%;
            height: 160px;
            background-repeat: no-repeat;
            background-position: center;
            background-size:80%;
            border: 1px solid #000;
            border-radius: 6px;
            margin-bottom: 2%;
        }
        #imagen-amadeus{background-image: url("../img/others/amadeus.jpg");}
        #imagen-galileo{background-image: url("../img/others/galileo.jpg");}
        #imagen-sabre{background-image: url("../img/others/sabre.jpg");}
        div#info {
            position: relative;
            overflow:hidden;
            width: 100%;
            height: 100%;
            background-color: rgba(31, 31, 31, 0.66);
            opacity:0;
            transition: opacity 0.3s;
            text-align: center;
        }
        div.imagen:hover div#info {
            opacity:1;
        }
        p#headline {
            position: absolute;
            font-size: 20PX;
            margin-left: -75px;
            margin-top: 15px;
            transition: margin-left 0.3s;
            color: #fff;
        }
        div.imagen:hover p#headline {
            margin-left: 40%;
        }
        p#descripcion {
            font-size: 17px;
            text-align: justify;
            margin-top: 200px;
            transition: margin-top 0.4s;
            color: #fff;
            margin: 5%;
        }
        div.imagen:hover p#descripcion {
            margin-top: 48px;
        }
        .linkgds {
            text-decoration: none;
            font-weight: normal;
        }
        .linkgds:hover {
            text-decoration: none;
            color: #fff;
        }
