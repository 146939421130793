/*------------------------------------*\
    #IMAGES
\*------------------------------------*/

.img-full {
    display: block;
    height: auto;
    margin-bottom: $inuit-base-spacing-unit;
    width: 100%;
} 

svg:not(:root) {
    overflow: visible;
  }
  