///*------------------------------------*\
//    #LOADING
//\*------------------------------------*/


/********** GLOBAL OVERLAY **********/
.overlay {
    background-color: rgba(255,255,255,0.65);
    display: table;
    height: 100%;
    overflow: hidden;
    position: fixed;
    width: 100%;
    z-index: $z-index-loading-overlay;
}
.overlay--inDiv {
    position: relative;
}
.overlay-center {
    color: $color-loading-main;
    display: table-cell;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    padding: 0 10px;
    vertical-align: middle;
}
.overlay-center p {
    border-radius: 10px;
    padding: 15px 0;
}





/********** Spin JS Big **********/
.spinOverlay {
    height: 128px;
    background: url("/assets/common_assets/img/icon/spin.gif") no-repeat center;
    margin-bottom: 18px;
}
/*.spinner {
    top: calc(50% - 35px);
}*/

.spinOverlayLoading {
    height: 250px;
    width: 320px;
    // background: url("/assets/common_assets/img/icon/spinLoading.gif") no-repeat center;
    background: url("/assets/octopus_assets/img/loading/octopus_cargando.gif") no-repeat;
    background-position: center;
    background-size: contain;
    margin-bottom: $inuit-base-spacing-unit--small;
    @media screen and (max-width: 768px) {
        height: 128px;
        background: url("/assets/common_assets/img/icon/spinLoading.gif") no-repeat center;
    }
    @media screen and (max-width: 479px) {
        height: 128px;
        background: url("/assets/common_assets/img/icon/spinLoading.gif") no-repeat center;
    }
}

.spin-text {
    height: 30px;
    text-align: center;
    font-weight: bold;
}





/********** Spin Gif Small **********/
.circularG {
    position: relative;
    width: 17px;
    height: 17px;
    background: url('/assets/common_assets/img/icon/ajax-loader.gif') no-repeat;
}





/********** LOADING BEACH **********/

$beach-height:               350px;
$beach-height-palm:          320px;
$beach-animation-duration:   22s;

.beach {
    background: url('/assets/common_assets/img/loading/sand.jpg') no-repeat;
    @include media-query(palm) {
        height: $beach-height-palm;
    }
    background-size: cover;
    background-position: center;
    border-radius: $base-round;
    height: $beach-height;
    overflow: hidden;
    position: relative;
}
.beach__humidity, .beach__foam {
    position: absolute;
    width: 112%;
    height: 100%;
}
.beach__reflect-top, .beach__reflect-btm {
    position: absolute;
    height: 100%;
    width: 110%;
    opacity: 0.28;
}
.beach__humidity {
    animation: humidity $beach-animation-duration ease-in-out infinite;
    background: url('/assets/common_assets/img/loading/humidity.png') no-repeat;
    background-size: 100% auto;
}
.beach__foam {
    animation: foam $beach-animation-duration ease-in-out infinite;
    background: url('/assets/common_assets/img/loading/foam.png') no-repeat;
    background-size: 100% auto;
}
.beach__reflect-btm {
    animation: reflect-btm $beach-animation-duration ease-in-out infinite alternate;
    background: url('/assets/common_assets/img/loading/reflect.png') repeat;
}
.beach__reflect-top {
    animation: reflect-top $beach-animation-duration ease-in-out infinite alternate;
    background: url('/assets/common_assets/img/loading/reflect.png') repeat;
}
.beach__search-text {
    background:url('/assets/common_assets/img/loading/sand_text.png') repeat;
    color:transparent;
    margin: auto;
    opacity: 0.8;
    padding-left: 10%;
    padding-right: 10%;
    position: absolute;
    text-align:center;
    top: 50%;
    width: 100%;
    @include font-size(50px);
    @include media-query(palm) {
        @include font-size(38px);
    }
    line-height: 1.3;

    transform: translateY(-50%);
    -webkit-font-smoothing:antialiased;
    -webkit-text-fill-color:transparent;
}
.beach__search-text-ie {
    background:none !important;
    color:#D49A6A !important;
}
.beach__search-logo {}
.beach__search-text-1 {
    animation: search-text-1 $beach-animation-duration ease-in-out infinite alternate;
}
.beach__search-text-2 {
    animation: search-text-2 $beach-animation-duration ease-in-out infinite alternate;
}
.beach__error-text {
    position: relative;
    color: $color-loading-negative;
    @include font-weight(bold);
    font-size: 25px;
    text-align: center;
    margin: auto;
}
@include keyframes(search-text-1) {
    0%   { opacity: 0.8;}
    30%  { opacity: 0;}
    50%  { opacity: 0;}
    75%  { opacity: 0;}
    100% { opacity: 0.8;}
}
@include keyframes(search-text-2) {
    0%   { opacity: 0;}
    25%  { opacity: 0;}
    50%  { opacity: 0.8;}
    80%  { opacity: 0}
    100% { opacity: 0;}
}
@include keyframes(humidity) {
    0%   { top: 90%; left: -5%;}
    25%  { top: 5%; left: 0%;}
    30%  { top: 7%; left: 0%;}
    50%  { top: 90%; left: -5%;}
    75%  { top: 5%; left: -2%;}
    80%  { top: 7%; left: 0%;}
    100% { top: 90%; left: -5%;}
}
@include keyframes(foam) {
    0%   { top: 85%; left: -8%; opacity: 0.40;}
    12%  { opacity: 0.99; left: -10%;}
    25%  { top: 5%; left: -4%; opacity: 0.65;}
    37%  { opacity: 0.99; left: -3%;}
    50%  { top: 85%; left: -2%; opacity: 0.50;}
    62%  { opacity: 0.99; left: 0;}
    75%  { top: 5%; left: -4%; opacity: 0.65;}
    87%  { opacity: 0.99; left: -6%;}
    100%  { top: 85%; left: -8%; opacity: 0.40;}
}
@include keyframes(reflect-btm) {
    0%   { top: 55px; left: -10%;}
    25%  { top: 65px; left: 0;}
    50%  { top: 60px; left: -10%;}
    75%  { top: 75px; left: 0;}
    100% { top: 65px; left: -10%;}
}
@include keyframes(reflect-top) {
    0%   { top: 85px; left: 0;}
    25%  { top: 75px; left: -10%;}
    50%  { top: 85px; left: 0;}
    75%  { top: 85px; left: -10%;}
    100% { top: 85px; left: 0;}
}

@-webkit-keyframes rotate {
    from { -webkit-transform: rotate(0deg) }
    to { -webkit-transform: rotate(360deg) }
}
@-moz-keyframes rotate {
    from { -moz-transform: rotate(0deg) }
    to { -moz-transform: rotate(360deg) }
}
@-o-keyframes rotate {
    from { -o-transform: rotate(0deg) }
    to { -o-transform: rotate(180deg) }
}
@-ms-keyframes rotate {
    from { -ms-transform: rotate(0deg) }
    to { -ms-transform: rotate(360deg) }
}

.js-resultsDetail-loader-exp {
  width: 150px;
  height: 150px;
  border: 10px solid #eee;
  border-top: 10px solid #1370B3;
  border-radius: 50%;
  animation-name: girar;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  margin-left: auto;
  margin-right: auto;
}
@keyframes girar {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
