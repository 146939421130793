 ///*------------------------------------*\
//    #NAV-MAIN
//\*------------------------------------*/
.nav-main {
    float: left;
    margin: 13px 0 0px;
    padding-left: 30px;
    @include media-query(palm) {
        margin: $inuit-base-spacing-unit--small 0;
        padding: 0 $inuit-base-spacing-unit--small;
    }
}
.nav-main__grid {
    float: left;    
    @include media-query(palm) {
        width: 50%;
        // border-top: 2px solid $color-frame-border;
        &:nth-child(1), &:nth-child(2) {
            border-top-width: 0;
        }
        /*        &:nth-child(odd) {
                    border-right: 2px solid $color-frame-border;
                }*/
        &:nth-last-child(2):nth-child(odd) {
            // last child element if there is odd number of elements, last one being hidden
            width: 50%;
            border-right-width: 0;
        }
    }
    @media screen and (min-width:1024px) and (max-width:1150px){
        @include span (1 of 9);
    }
}
.nav-main__item {    
    @include media-query(palm) {
        color: $color-main;
    }
    @include media-query(lap-and-up){
        &.is-active .nav-main__title {
            border-bottom: 2px inset $color-main;
        }
    }
}

.expe-main{
    @include media-query(desk){
        width: 100%;
        &.is-active .nav-main__icon {
            background-position-x: 2px;
        }
    }
}
.nav-main__icon {
    @include media-query(palm) {
        border: 2px solid $color-main;
    }    
}

.nav-main__icon--flight {
    background-image: url(/assets/octopus_assets/img/navbar/flight.svg);
}
.nav-main__icon--flighthotel {
    background-image: url(/assets/octopus_assets/img/navbar/flight-hotel.svg);
}
.nav-main__icon--hotel {
    background-image: url(/assets/octopus_assets/img/navbar/hotel.svg);
}
.nav-main__icon--package {
    background-image: url(/assets/octopus_assets/img/navbar/vacations.svg);
}
.nav-main__icon--car {
    background-image: url(/assets/octopus_assets/img/navbar/cars.svg);
}
.nav-main__icon--train {
    background-image: url(/assets/octopus_assets/img/navbar/trains.svg);
}
.nav-main__icon--cruise {
    background-image: url(/assets/octopus_assets/img/navbar/cruise.svg);
}
.nav-main__icon--bus {
    background-image: url(/assets/octopus_assets/img/navbar/bus.svg);
}
.nav-main__icon--insurance {
    background-image: url(/assets/octopus_assets/img/navbar/insurance.svg);
}
.nav-main__icon--corporate {
    background-image: url(/assets/octopus_assets/img/navbar/icon-corporate.svg);
}
.nav-main__icon--experience {
    background-image: url(/assets/octopus_assets/img/navbar/expirience.svg);
}
.nav-main__title{
    color: $color-main;    
}
.nav-main--promo .nav-main__item {
    background: none;
    border: 1px solid $color-nav-main-item--promo;
    @include span(1 of 7);
}
.nav-main__item * {
    display: block;
    // margin: auto;
}
.mail_agent_nav li > a {
    padding: 0;
    margin: 0;
    line-height: 0px;
}
#menu-info-octopus a{
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 17px;
    line-height: 1.125;
    font-weight:bolder;
    color:#1370b3
}
