///*------------------------------------*\
//    #SEARCH CAR
//\*------------------------------------*/

.search__col-car-city {
    @include span(4 of 14);
    @include media-query(lap) {
        @include span(1 of 2);
    }
    @include media-query(palm) {
        @include span(full);
    }
}
.search__col-car-date {
    @include span(3 of 14);
    @include media-query(lap) {
        @include span(1 of 2);
    }
    @include media-query(palm) {
        @include span(full);
    }
}





// IFrame
.search-car__iframe-container {
    display: block;
    margin: auto;
    overflow: hidden;
    margin-bottom: $inuit-base-spacing-unit--large;
    width: 1000px;
     @include media-query(lap) {
        width: 510px;
    }
    @include media-query(palm) {
        height: 400px;
        width: 450px;
    }
}
.search-car__iframe {
    height: 1200px;
    width: 980px;
    @include media-query(lap) {
        width: 500px;
    }
    @include media-query(palm) {
        height: 350px;
        width: 450px;
        margin: auto; 
        display: block;
        left: -30px;
        position: relative;
        top: 0px;
    }
}





// Search Engine