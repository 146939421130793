///*------------------------------------*\
//    #SEARCH FLIGHT
//\*------------------------------------*/
.search__col-flight-city {
    //@include span(1 of 4);
    width: 25%;
    float: left;
    padding-left: 9px;
    padding-right: 9px;
    @include media-query(lap) {
        //@include span(1 of 2);
        width: 50%;
        float: left;
        padding-left: 9px;
        padding-right: 9px;
    }
    @include media-query(palm) {
        //@include span(full);
        width: 100%;
        float: left;
        padding-left: 9px;
        padding-right: 9px;
    }
}
.search__col-flight-city-flight {
    /*@include span(1 of 4);*/
    //width: calc((1 / 4) * 100%);
    width: calc((11 / 50) * 100%);
    float: left;
    padding-left: 9px;
    padding-right: 9px;
    @media (max-width: 991px) {
        /*/* @include span(full); */ 
        //width: calc((1 / 1) * 100%);
        width: calc((48 / 100) * 100%);
        float: left;
        padding-left: 9px;
        padding-right: 9px;
    }
}
.dispo__col-search{
    /*
    .text-input {
        background-color: transparent;
        border: 0px solid transparent;
        color: #ffffff;
    }
    */
    .form-group{
        margin-bottom:0px;
    }
}
.search__col-flight-city_blog {
    width: 100%;
    @include media-query(lap) {
        @include span(1 of 2);
    }
    @include media-query(palm) {
        /* @include span(full); */ width: calc((1 / 1) * 100%);
    }
}

.search__col-flight-date {
    //@include span(1 of 6);
    width: 16.66667%;
    float: left;
    padding-left: 9px;
    padding-right: 9px;
    @include media-query(portable) {
        //@include span(1 of 2);
        width: 50%;
        float: left;
        padding-left: 9px;
        padding-right: 9px;
    }
}

.search__col-flight-date-flight {
    /*@include span(1 of 6);*/
    //width: 10%;
    width: 12.66667%;
    float: left;
    padding-left: 9px;
    padding-right: 9px;

    @media (max-width: 991px) {
        /*@include span(1 of 2);*/
        width: 31.33333%;
        float: left;
        padding-left: 9px;
        padding-right: 9px;
    }
}
.divider-left{
    border-left-color: #fff;
    border-left-width: 2px;
    border-left-style: solid;
    height: 3.5rem;
}
.divider-right{
    border-right-color: #fff;
    border-right-width: 2px;
    border-right-style: solid;
    height: 3.5rem;
    @include media-query(palm) {
        border-right-style: unset;
    }
}
.search__col-flight-date_blog {
    width: 100%;
    @include media-query(lap) {
        @include span(1 of 2);
    }
    @include media-query(palm) {
        /* @include span(full); */ width: calc((1 / 1) * 100%);
    }
}
.search__col-flight-passengers {
    position: relative;
    /*@include span(1 of 6);*/
    width: calc((1 / 6) * 100%);
    float: left;
    padding-left: 9px;
    padding-right: 9px;
    @media (max-width: 991px) {
		width: calc((35 / 100) * 100%);
        float: left;
        padding-left: 9px;
        padding-right: 9px;
    }
}
.search__col-flight-passengers_blog {
    position: relative;
    width: 100%;
    @include media-query(lap) {
        @include span(1 of 2);
    }
    @include media-query(palm) {
        /* @include span(full); */ width: calc((1 / 1) * 100%);
    }
}
.search__col-flight-passengers-block {
    @extend %picker-flight;
    position: absolute;
    /*right: 0;*/
    right: auto;
    /*min-width: 260px;*/
    min-width: 220px;
    background-color: white;
    border: 1px solid $color-frame-border;
    /*@include span(1 of 5);*/
    width: 20%;
    float: left;
    padding-left: 9px;
    padding-right: 9px;
    //@include media-query(portable) {
    @media (max-width: 991px) {
        /*@include span(9 of 10);*/
        background-color: #ffffff;
        width: 90%;
        float: left;
        padding-left: 9px;
        padding-right: 9px;
    }
    @include media-query(palm) {
        background-color: #ffffff;
        position: relative;
        min-width: 400px;
        float: inline-end;
        padding-left: 9px;
        padding-right: 9px;
    }
    padding-top: 15px;
    padding-bottom: 9px;
}
.search__col-flight-passengers-block_blog {
    position: absolute;
    right: 0;
    min-width: 305px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.12);
    width: 20%;
    float: left;
    padding-left: 9px;
    padding-right: 9px;
    padding-top: 9px; }

.search-flight__col-advanced-options {
    /*@include span(1 of 3);*/
    width: 33.33333%;
    float: left;
    padding-left: 9px;
    padding-right: 9px;
    @include media-query(palm) {
        width: 100%;
    }
}

.search-flight__col-advanced-options-flight {
    /*@include span(1 of 3);*/
    //width: 33.33333%;
    width: 19%;
    float: left;
    padding-left: 9px;
    padding-right: 9px;
    @media (max-width: 991px) {
        width: 100%;
        text-align: center;
    }
}

.search-flight__col-advanced-options-two {
    width: 57%;
    float: left;
    padding-left: 9px;
    padding-right: 9px;
    @media (max-width: 991px) {
        width: 100%;
    }
}

.search-flight__col-footer {
    /* @include span(full); */ width: calc((1 / 1) * 100%);
}

.search-flight__col-check-options {
    //@include media-query(lap-and-up) {
    @media (min-width: 992px) {
        &:not(:nth-child(1)) {
            margin-left: $inuit-base-spacing-unit--small;
        }
    }
    @media (max-width: 991px) {
    //@include media-query(palm) {
        width: 50%;
    }
}

.search-flight__col-check-options-two {
    //@include media-query(lap-and-up) {
    @media (min-width: 992px) {
        &:not(:nth-child(1)) {
            margin-left: $inuit-base-spacing-unit--small;
        }
    }
    @media (max-width: 991px) {
    //@include media-query(palm) {
        width: 100%;
    }
}

.calendar-text{
    line-height: 1rem;
}

.search-flight__col-more-options {
    //@include media-query(palm) {
    @media (max-width: 991px) {
        margin: 1.5rem 0;
    }
}

.search__col-flight-city-icon {
    width: 3%;
    float: left;
    padding-top: 1.875rem;
    @media (max-width: 991px) {
        padding-top: 1.5rem;
    }
}
.search__col-flight-date-flight input[type="text"]{
    padding-right: 0px;
}
.search__col-flight-date-icon {
    width: 2%;
    float: left;
    padding-top: 2.5rem;
    @media (max-width: 991px) {
        padding-top: 2.0667rem;
    }
}

.search__col-flight-separator {
    visibility: visible;
    width: 1%;
    float: left;
    color: #ffffff;
    font-size: xxx-large;
    @media (max-width: 991px) {
        visibility: hidden;
        width: 0%;
    }
}

.search__col-flight-send-button {
    visibility: visible;
    width: 4%;
    float: left;
    @media (max-width: 991px) {
        visibility: hidden;
        width: 0%;
    }
}

.search-flight__col-footer-search {
    // visibility: hidden;
    @media (max-width: 991px) {
        visibility: visible;
        /* @include span(full); */ width: calc((1 / 1) * 100%);
    }
}

.search-flight__div-title-preferences {
    width: 57%;
    color: #ffffff;
    float: left;
    @media (max-width: 991px) {
        width: 100%;
        color: #042A44;
        font-family: 'Roboto';
        font-weight: 500;
        text-align: center;
        margin-bottom: 1.5rem;
    }
}

.search-flight__div-title-time-preferences {
    display: initial;
    width: 38%;
    color: #ffffff;
    float: left;
    @media (max-width: 991px) {
        display: none;
        width: 0%;
        color: #042A44;
        font-family: 'Roboto';
        font-weight: 500;
    }
}

.search-flight__div-title-time-preferences-palm {
    display: none;
    width: 0%;
    color: #ffffff;
    float: left;
    @media (max-width: 991px) {
        display: initial;
        width: 100%;
        color: #042A44;
        font-family: 'Roboto';
        font-weight: 500;
        text-align: center;
        margin-bottom: 1.5rem;
    }
}

.search-flight__div-title-flex-preferences {
    width: 57%;
    color: #ffffff;
    float: left;
    font-family: sans-serif;
    @media (max-width: 991px) {
        width: 100%;
        color: #042A44;
    }
}

.search-flight__div-space {
    width: 5%;
    visibility: visible;
    float: left;
    @media (max-width: 991px) {
        width: 0%;
        visibility: hidden;
    }
}

.search-flight__div-space-two {
    width: 43%;
    visibility: visible;
    float: left;
    @media (max-width: 991px) {
        width: 0%;
        visibility: hidden;
    }
}

.search-flight__div-title-baggage-preferences {
    width: 0%;
    visibility: hidden;
    display: none;
    color: #ffffff;
    @media (max-width: 991px) {
        width: 100%;
        visibility: visible;
        display: block;
        color: #042A44;
    }
}

.div-show-advice-passengers-palm {
    visibility: hidden;
    height: 0px;
    @media (max-width: 991px) {
        visibility: visible;
        min-height: 60px;
    }
}

.text-advice-passengers-palm {
    font-family: sans-serif;
    font-size: 13px;
    vertical-align: top;
}

.search__col-flight-city-journey {
    width: calc((3 / 10) * 100%);
    float: left;
    padding-left: 9px;
    padding-right: 9px;
    /*
    @include media-query(lap) {
		width: calc((48 / 100) * 100%);
        float: left;
        padding-left: 9px;
        padding-right: 9px;
    }
    */
    @media (max-width: 991px) {
        /*/* @include span(full); */ 
        //width: calc((1 / 1) * 100%);
        width: calc((48 / 100) * 100%);
        float: left;
        padding-left: 9px;
        padding-right: 9px;
    }
}

.search__col-flight-date-journey {
    width: calc((3 / 10) * 100%);
    float: left;
    padding-left: 9px;
    padding-right: 9px;

    //@include media-query(portable) {
    @media (max-width: 991px) {
        width: calc((4 / 10) * 100%);
        float: left;
        padding-left: 9px;
        padding-right: 9px;
    }
}

.card-slide-content {
    height: auto;
    box-shadow: 0px 0px 0px 0px;
    border-radius: 0px;
    @media (max-width: 991px) {
        height: 12rem;
        box-shadow: 0px 0px 0px 0px;
        border-radius: 5px;
    }
}

/*
    Clases que aplican para cambio dinámico de clase cuando se tiene solo ida o multidestino
*/

.search__col-flight-date-left-only-go {
    width: calc((1 / 5) * 100%);
    float: left;
    padding-left: 9px;
    padding-right: 9px;

    @media (max-width: 991px) {
        width: calc((47 / 100) * 100%);
        float: left;
        padding-left: 9px;
        padding-right: 9px;
    }
}

.search__col-flight-date-left-only-go input[type="text"]{
    padding-right: 0px;
}

.search__col-flight-date-icon-only-go {
    width: 0%;
    float: left;
}

.search__col-flight-date-right-only-go {
    width: 0%;
    float: left;
}

.search__col-flight-date-right-only-go input[type="text"]{
    padding-right: 0px;
}

.search__col-flight-passengers-only-go {
    position: relative;
    width: calc((6 / 25) * 100%);
    float: left;
    padding-left: 9px;
    padding-right: 9px;
    @media (max-width: 991px) {
		width: calc((49 / 100) * 100%);
        float: left;
        padding-left: 9px;
        padding-right: 9px;
    }
}

.search__col-flight-passengers-avail {
    position: relative;
    /*@include span(1 of 6);*/
    width: calc((1 / 6) * 100%);
    float: left;
    padding-left: 9px;
    padding-right: 9px;
    @media (max-width: 991px) {
		width: calc((35 / 100) * 100%);
        float: left;
        padding-left: 9px;
        padding-right: 9px;
    }
}

.search__col-flight-passengers-avail-block {
    @extend %picker;
    position: relative;
    /*right: 0;*/
    right: auto;
    min-width: 260px;
    background-color: #ffffff;
    border: 1px solid $color-frame-border;
    /*@include span(1 of 5);*/
    width: 20%;
    float: left;
    padding-left: 9px;
    padding-right: 9px;
    //@include media-query(portable) {
    @media (max-width: 991px) {
        /*@include span(9 of 10);*/
        position: relative;
        background-color: #ffffff;
        width: 90%;
        float: left;
        padding-left: 9px;
        padding-right: 9px;
    }
    @include media-query(palm) {
        background-color: #ffffff;
        position: relative;
        min-width: 400px;
        float: inline-end;
        padding-left: 9px;
        padding-right: 9px;
    }
    padding-top: 15px;
    padding-bottom: 9px;
}

.search-flight__div-title-preferences-avail {
    width: 57%;
    color: #042A44;
    float: left;
    font-family: 'Roboto';
    font-weight: 500;
    @media (max-width: 991px) {
        width: 100%;
    }
}

.search-flight__div-title-time-preferences-avail {
    display: block;
    width: 38%;
    color: #042A44;
    float: left;
    font-family: 'Roboto';
    font-weight: 500;
    @media (max-width: 991px) {
        display: none;
        width: 0%;
    }
}

.search-flight__div-title-time-preferences-palm-avail {
    display: none;
    width: 0%;
    color: #042A44;
    float: left;
    font-family: 'Roboto';
    font-weight: 500;
    @media (max-width: 991px) {
        display: block;
        width: 100%;
    }
}

.search-flight__div-title-flex-preferences-avail {
    width: 57%;
    color: #042A44;
    float: left;
    font-family: 'Roboto';
    font-weight: 500;
    @media (max-width: 991px) {
        width: 100%;
    }
}

.search__col-flight-city-icon-avail {
    width: 3%;
    float: left;
    padding-top: 0.5rem;
}

.search__col-flight-date-icon-avail {
    width: 2%;
    float: left;
    padding-top: 1rem;
}

.divider-left-avail{
    border-left-color: #000000;
    border-left-width: 2px;
    border-left-style: solid;
    height: 3.5rem;
    @media (max-width: 991px) {
        border-left-style: unset;
    }
}

.divider-right-avail{
    border-right-color: #000000;
    border-right-width: 2px;
    border-right-style: solid;
    height: 3.5rem;
    @media (max-width: 991px) {
        border-right-style: unset;
    }
}


.link-span2:hover,
.link-span2:active, .link-span2:focus
{
    color: #004386!important;
}

.link-span2.is-disabled{
    color: #fff;
    cursor: not-allowed;
}

.link-span2.is-disabled:hover,
.link-span2.is-disabled:active,
.link-span2.is-disabled:focus {
    color: #fff;
}

.search__option.link-span2 {
    font-weight: 800;
}

.search__light--box .link-span2 {
    font-weight: 500;
}

.search__light--box-flight .link-span2 {
    font-size: 1rem;
    font-weight: 600;
    text-decoration: underline;
    font-family: "Roboto";
}

.resume-flight__ticket__details-toggle .link-span2:hover .icon-btn,


.resume-flight__ticket__details-toggle .link-span2:active .icon-btn,


.resume-flight__ticket__details-toggle .link-span2:focus .icon-btn
{
    color: #fff!important;
    border-color: #fff!important;
}

a.link-span2 {
    text-decoration: none;
    color: #004386!important;
    cursor: pointer;
}