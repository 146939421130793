/*------------------------------------*\
    #FORM-GROUP
\*------------------------------------*/

.form-grid {
    @include span(1 of 3);
    @include media-query(palm) {
        @include span(full);
    }
}

.form-grid-register {
    @include span(full);
    @include media-query(palm) {
        @include span(full);
    }
}
.form-grid-register{
    width: 50%;
}