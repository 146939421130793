///*------------------------------------*\
//  #DETAIL-HOTEL
//\*------------------------------------*/

.detail-hotel__content {
    @include media-query(desk) {
        @include span(6 of 10);
    }
}
.detail-hotel__sidebar {
    @include span(4 of 10);
    @include media-query(portable) {
        /* @include span(full); */ width: calc((1 / 1) * 100%);
    }
}
.detail-hotel__title {
    @include media-query(palm) {
        margin-bottom: $inuit-base-spacing-unit--small;
    }
}
.detail-hotel__stars {
    height: $inuit-base-spacing-unit;
    vertical-align: baseline;
    /*filter: contrast(2) hue-rotate(200deg) saturate(1.5);*/
}
.detail-hotel__rating-disclaimer {
    min-width: 300px;
    margin-left: calc($inuit-base-spacing-unit--small/2).0;
}
.detail-hotel__label {
    font-size: 12px;
}
.detail-hotel__sidebar .detail-hotel__price-frame {
    margin-top: -1px;
}
@include media-query(palm) {
    .detail-hotel__sidebar .frame:last-of-type:not(:first-of-type) {
        border-radius: 0 !important;
    }
}
.detail-hotel__price {
    @include media-query(palm) {
        margin-bottom: $inuit-base-spacing-unit--small;
    }
}
@include media-query(palm) {
    .detail-hotel__price-frame--mobile {
        border-bottom: 1px solid rgba(255,255,255,.5) !important;
        border-radius: 0 0 5px 5px !important;
    }
}

.hotel__name {
    font-style: normal;
    font-size: 24px;
    line-height: 36px;
    color: #353535;
    margin-top: 24px;
    margin-bottom: 8px;
}
.hotel__address {
    font-size: 13px;
    line-height: 19px;
    color: #767676;
    text-transform: capitalize;
    margin-bottom: 38px;
}

.table > thead > tr > th{
    border-bottom: 1px solid #CCCCCC;
    text-align: center;
    vertical-align: middle;
    color: #000000;
    line-height: 19px;
    font-size: 13px;
    padding-top: 0;
}

.detail__info--roomtypes .detail-hotel__rooms-table tbody td {
    border: none !important;
    vertical-align: middle;
    font-size: 13px;
    line-height: 19px;
}

.detail__info--roomtypes .detail-hotel__rooms-table tbody td::first-letter {
    text-transform: capitalize;
}

.resume-search {
    margin-bottom: 24px !important;
}

.text-total-hotel {
    font-size: 10px;
    line-height: 15px;
    color: #7D7D7D;
}

.date-resume {
    background: #F4F8FF;
    border-radius: 7px;
    margin: 4px 0px;
    padding: 14px 8px;
    color: #353535;
    font-size: 12px;
    line-height: 18px;
}

.item-resume td {
    padding: 0 10px !important;
}

.item-resume td h6 {
    font-size: 12px;
    line-height: 15px;
    color: #7D7D7D;
    margin-bottom: 8px;
}

.item-resume table tr td:nth-child(1) {
    text-align: left;
    font-size: 12px;
    line-height: 18px;
    color: #353535;
    font-weight: bold;
}

.item-resume table tr td:nth-child(2) {
    text-align: right;
    font-size: 12px;
    line-height: 15px;
    color: #7D7D7D;
}
/*
comentario para evitar daño global de botones by yared
.btn--submit {
    background: #21C284 !important;
    border-radius: 10px !important;
    padding: 7px 48px !important;
}
*/
.pd0 {
    padding: 0 !important;
}

.pd15y {
    padding-top: 15px;
    padding-bottom: 15px;
}

.pt17 {
    padding-top: 17px !important;
}

.mb8 {
    margin-bottom: 8px !important;
}

span.value {
    font-weight: bold;
}

.list-radio > .list-checked, .list-radio--big > .list-checked, .list-checkbox > .list-checked {
    top: 1px;
    left: 1px;
    bottom: 2px;
    padding: 1px;
    right: 2px;
    background-color: #042A44;
}

.detail-hotel__carousel {
    margin-bottom: $inuit-base-spacing-unit;
    @include media-query(palm) {
        margin-bottom: -1px;
        margin-left: -$inuit-base-spacing-unit--small;
        margin-right: -$inuit-base-spacing-unit--small;
        width: calc(100% + #{$inuit-base-spacing-unit});
    }
}
.detail-hotel__carousel-disclaimer {
    margin-top: calc(2px - #{$inuit-base-spacing-unit});
    margin-bottom: $inuit-base-spacing-unit;
    @include media-query(palm) {
        margin-top: 1px;
        margin-bottom: 3px;
    }
}
.detail-hotel__carousel__image {
    background: no-repeat center center;
    height:300px;
    background-size: cover;
    @include media-query(palm) {
        height: 250px;
    }
}





.detail-hotel__rooms-table {
    margin-bottom: $inuit-base-spacing-unit;
}




.detail-hotel__map {
    border-radius: $base-round;
    border: 1px solid rgba(0,0,0,.07);
    height: 420px;
    margin-bottom: $inuit-base-spacing-unit;
    width: 100%;
    @include media-query(palm) {
        height: 200px;
    }
}
.detail-hotel__description {
    padding-bottom: $inuit-base-spacing-unit;
}
.detail-hotel__description-expanded {
    display: none;
    margin-bottom: $inuit-base-spacing-unit;
}
.detail-hotel__list {
    padding-bottom: $inuit-base-spacing-unit;
}

.detail-multi__discount-policies {
    font-size: .8em;
    font-weight: bold;
}

.js-showConditions h6 {
    font-weight: bold;
    margin-top: 3px;
}

.detail-hotel-image {
    border-radius: 5px 0px 0px 5px;
    background: url(/assets/aviatur_assets/img/error/noHotelPicture.jpg) no-repeat center center;
    background-size: cover;
    height: calc(24rem + 2rem);
    margin: 0;
}

.searchFlightHotel__instance-creator {
    padding: 0;
}
.small-room {
    @include media-query (palm) {
        width: 50%;
    }
}
