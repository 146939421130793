///*------------------------------------*\
//    #BUNDLE CONTENT
//\*------------------------------------*/

.content {
    background-color: white;
    border-radius: 5px;
    margin: 15px 0 0 0;
    padding: 15px;
}
.content-col {
    @include span(1 of 2);
    @include media-query(palm) {
        @include span(full);
    }
}
.content-footer {
    margin: 0;
}

.conteng-img {
    float: left;
    background-color: white;
    margin: 0 5px 5px 5px ;
}
.content-menu-li-a {
    display: inline-block !important;
}
.content-nav {
    background-color: $color-main;
    position: relative;
}

.content-navbar:hover {
    background-color: $gray-accent-light;
}

.content-navbar, 
.content-pagination > li > a,
.content-pagination > li > span {
    background-color: $gray-accent-light;
}

.pagination > li > a:focus,
.content-pagination > li > a:hover, 
.content-pagination > li > span:hover,
.content-pagination > li > span:focus {
    color: $gray-accent-light;
    background-color: $gray-accent-light;
    border-color: $gray-accent-light;
}