///*------------------------------------*\
//    #NAV-TABS
//\*------------------------------------*/

//
// Navs
// --------------------------------------------------


// Base class
// --------------------------------------------------

.nav {
    margin-bottom: $inuit-base-spacing-unit;
    padding-left: 0; // Override default ul/ol
    list-style: none;
    @extend .clearfix;

    > li {
        position: relative;
        display: block;

        > a {
            position: relative;
            display: block;
            padding: $inuit-base-spacing-unit--small;
            &:hover,
                &:focus {
                text-decoration: none;
                background-color: $color-nav-hover-bg;
            }
        }
    }
}


// Tabs
// -------------------------

// Give the tabs something to sit on
.nav-tabs {
    margin-left: 0;
    border-bottom: 1px solid $color-nav-border;
    > li {
        float: left;
        // Make the list-items overlay the bottom border
        margin-bottom: -1px;

        // Actual tabs (as links)
        > a {
            margin-right: 2px;
            height: 2.5rem;
            border: 1px solid transparent;
            border-radius: $base-round $base-round 0 0;
            &:hover {
                border-color: transparent
            }
        }

        // Active state, and its :hover to override normal :hover
        &.active > a {
            &,
            &:hover,
                &:focus {
                background-color: transparent;
                border: 1px solid $color-nav-active-border;
                border-bottom-color: #f9f9f9;
                cursor: default;
            }
        }
    }
}



// Tabbable tabs
// -------------------------

// Hide tabbable panes to start, show them when `.active`
.tab-content {
    > .tab-pane {
        display: none;
        visibility: hidden;
    }
    > .active {
        display: block;
        visibility: visible;
    }
}




.nav--light {
    > * {
        @include attention() {
            outline: 0;
            background-color: rgba($gray-base, .03);
        }
    }
    > .active {
        background-color: rgba($gray-base, .03);
    }
}




.nav-option {
    display: inline-block;
    position: relative;
    float: left;
    background-color: $color-frame-bg;
    text-align: center;
    padding: $inuit-base-spacing-unit;
    outline: 0;
    border-radius: 0;
    border: 0px solid $color-frame-border;
    border-width: 1px 0 1px 0;
    cursor: pointer;
    &:nth-child(n+2) {
        border-left-width: 1px;
    }
    &:first-child {
        border-left-width: 1px;
    }
    &:last-child {
        border-right-width: 1px;
    }
    &:hover, &:focus {
        background-color: #fff;
    }
    &.active {
        background-color: transparent;
        border-bottom-color: transparent;
    }
    &.active:after {
        content: '';
        position: absolute;
        background-color: $color-btn;
        width: 100%;
        height: 4px;
        top: -1px;
        left: 0;
    }
}



.option-toggle {
    &.active {
        font-weight: 600;
        color: $gray-main-light;
    }
    &:not(.active) {
        @extend .link-span;
    }
}

