///*------------------------------------*\
//    #FIXED
//\*------------------------------------*/

.fixed {
    background-color: white;
    position: fixed;
    top: 0;
    z-index: $z-index-fixed;
    width: 100%;
    left: 0;
    padding: 9px;
}
.fixed--top {
    top: 0;
    border-bottom: 1px solid $color-fixed-border;
    box-shadow: 0 3px 3px -2px $color-fixed-border;
}
.fixed--bottom {
    @extend .fixed;
    top: auto;
    bottom: 0;
    border-bottom-width: 0;
}