/*------------------------------------*\
    #TABLES
\*------------------------------------*/

// Adapted from Bootstrap Tables

table {
  background-color: transparent;
}
th {
  text-align: left;
}


// Baseline styles

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 0;
  // Cells
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        padding: $inuit-base-spacing-unit--small;
        line-height: $inuit-base-line-height;
        vertical-align: top;
        border-top: 1px solid $color-table-border;
      }
    }
  }
  // Bottom align for column headings
  > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 2px solid $color-table-border;
  }
  // Remove top border from thead by default
  > caption + thead,
  > colgroup + thead,
  > thead:first-child {
    > tr:first-child {
      > th,
      > td {
        border-top: 0;
      }
    }
  }
  // Account for multiple tbody instances
  > tbody + tbody {
    border-top: 2px solid $color-table-border;
  }

  // Nesting
  .table {
    background-color: $color-table-bg;
  }
}


// Condensed table w/ half padding

.table-condensed {
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        padding: $inuit-base-spacing-unit--tiny;
      }
    }
  }
}


// Bordered version
//
// Add borders all around the table and between all the columns.

.table-bordered {
  border: 1px solid $color-table-border;
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        border: 1px solid $color-table-border;
      }
    }
  }
  > thead > tr {
    > th,
    > td {
      border-bottom-width: 2px;
    }
  }
}


// Table cell sizing
//
// Reset default table behavior

table col[class*="col-"] {
  position: static; // Prevent border hiding in Firefox and IE9-11 (see https://github.com/twbs/bootstrap/issues/11623)
  float: none;
  display: table-column;
}
table {
  td,
  th {
    &[class*="col-"] {
      position: static; // Prevent border hiding in Firefox and IE9-11 (see https://github.com/twbs/bootstrap/issues/11623)
      float: none;
      display: table-cell;
    }
  }
}

#fos_user_registration_form_documentType option {
  display: none;
}

#fos_user_registration_form_documentType option[value="3"],
#fos_user_registration_form_documentType option[value="4"] {
  display: block;
}