                                                                                                    /*------------------------------------*\
    #AVIATUR
\*------------------------------------*/
 
/**
 * CONTENTS
 *
 * SETTINGS
 * Default..............inuitcss’ default settings. [INUIT]
 * Global...............Globally-available variables and settings/config. [CUSTOM]
 * Colors...............Manage our color palette in isolation. [CUSTOM]
 * Grid.................settings for Susy grid tool, declare ALL colors here [CUSTOM]
 * Reponsive............inuitcss responsive breakpoints tool settings [INUIT]
 *
 *
 * TOOLS
 * Functions............inuitcss’ default functions. [INUIT]
 * Mixins...............inuitcss’ default mixins. [INUIT]
 * Mixins...............Extension of inuitcss’ mixins. [CUSTOM]
 * Susy.................Famous Susy grid tool (requires Susy settings). [SUSY]
 * Responsive...........Responsive breakpoints inuitcss tool (requires responsive settings). [INUIT]
 * Aliases..............Aliases onto longer inuitcss variables and mixins. [CUSTOM]
 *
 *
 * GENERIC
 * Normalize.css........A level playing field. [NORMALIZE THROUGH INUIT]
 * Reset................A pared back reset to remove margins. [INUIT]
 * Compass..............Compass SASS gestion [COMPASS]
 * Box-sizing...........Better default `box-sizing`. [INUIT]
 * Shared...............Sensibly and tersely share global commonalities. [INUIT]
 *
 *
 * BASE
 * Page.................Page-level styles (HTML element). [INUIT]
 * Page.................Our extension of the inuitcss page module. [CUSTOM]
 * Type.................Misc type styles [CUSTOM]
 * Links................Hyperlink styles [CUSTOM]
 * Section..............Section tag style[CUSTOM]
 * Headings.............Heading styles [INUIT]
 * Lists Bare...........inuitcss list styles [INUIT]
 * Lists Inline.........inuitcss list styles [INUIT]
 * Images...............inuitcss images [INUIT]
 *
 *
 * OBJECTS
 * Wrappers.............Wrappers and page constraints. [CUSTOM]
 * Buttons..............inuitcss’ buttons module. [INUIT]
 * Buttons..............extension to buttons module. [CUSTOM]
 * Icons Webfont........Grunt generated icon font [CUSTOM]
 * Form Grid............Grid system for form component [CUSTOM]
 * Form Group...........Enclose input with features [CUSTOM]
 * Input Group..........Enclose input with features [CUSTOM]
 * Label................Label styles [CUSTOM]
 * Text Input...........Text input styles [CUSTOM]
 * Checkbox.............Checkbox styles [CUSTOM]
 * Tables...............Tables module [CUSTOM FROM BOOTSTRAP]
 * Flag.................The flag object. [INUIT]
 *
 *
 * COMPONENTS
 * Buttons..............Extension of inuitcss’ buttons.
 * Forms................Form styles
 * Icons................Icons styles
 *
 * Frames...............Join styled div together
 * Modals...............Adaptation of Bootstrap modals
 * Nav tabs...............Adaptation of Bootstrap Nav tabs
 * Slidepanel...........Slidepanel module
 * Fixed................Fix to top module
 * Datepicker...........Datepicker module
 * Tooltips.............Tooltip module
 * Loading..............Loading module
 * jQuery UI............jQuery UI Overides
 *
 * Page-head............Page header styles
 * Page-foot............Page footer styles
 * Nav-top..............Login/Mailing Nav style
 * Nav-main.............Main Nav Styles
 * Nav-foot.............Footer nav (e.g. compagnies)
 *
 * Promo-header.........Promo style in header
 * Promo-banner.........Promo style as banner
 * Promo-box............Promo style as box
 *
 * Search...............Shared search style
 * Search Flight........Flight search style
 * Search Hotel.........Hotel search style
 * Search Car...........Car search style
 * Search Package.......Package search style
 * Dispo Shared.........Shared Availabily style
 * Dispo Flight.........Flight Availabily style
 * Dispo Hotel..........Hotel Availabily style
 * Dispo Mutli..........Multi Availabily style
 * Detail Shared........Shared Detail style
 * Detail Flight........Flight Detail style
 * Detail Hotel.........Hotel Detail style
 * Resume Shared........Shared Resume Style
 * Resume Flight........Shared Resume Style
 * Resume Hotel.........Shared Resume Style
 *
 * Page Corporate ......Styles for the Corporate homepage
 * Bundle Content ......Styles for the Bundle "Content"
 * Bundle Customer .....Styles for the Bundle "Customer"
 *
 *
 * TRUMPS
 * Types................Types helpers. [CUSTOM]
 * Print................Print helpers. [INUIT]
 * Print................Print helpers. [CUSTOM]
 * Cursors..............Cursor helpers. [CUSTOM]
 * Spacing..............Micro-level spacing. [INUIT]
 * Float................Pull-left and pull-right helpers. [CUSTOM]
 * Show/hide............Helpers to show and side stuff. [CUSTOM]
 * Margin/padding.......Helpers to quit margin or padding. [CUSTOM]
 * Accessibility........Helpers fo accessibility [CUSTOM FROM BOOTSTRAP]
 * Responsive JS........Helpers to link JS to CSS responsive classes. [CUSTOM]
 */

/**
 * #SETTINGS
 */
@import "_settings.defaults";
@import "../../../bower_components/inuit-defaults/settings.defaults";
$inuit-base-spacing-unit--tiny:  round($inuit-base-spacing-unit / 4);
$inuit-base-spacing-unit--huge:   round($inuit-base-spacing-unit * 4);
@import "_settings.global";
@import "_settings.colors";
@import "../../../bower_components/inuit-responsive-settings/settings.responsive.scss";

/**
 * #TOOLS
 */
@import "../../../bower_components/inuit-functions/tools.functions";
@import "../../../bower_components/inuit-mixins/tools.mixins";
@import "_tools.mixins";
@import "_settings.susy";
@import "../../../bower_components/susy/sass/susy";
@import "../../../bower_components/inuit-responsive-tools/_tools.responsive";
@import "_tools.aliases";

/**
 * #GENERIC
 */
@import "../../../bower_components/inuit-normalize/generic.normalize";
@import "../../../bower_components/inuit-reset/generic.reset";

@import "../../../bower_components/inuit-box-sizing/generic.box-sizing";
@import "../../../bower_components/inuit-shared/_generic.shared";

/**
 * #BASE
 */
@import "../../../bower_components/inuit-page/base.page";
@import "_base.page";
@import "_base.type";
@import "_base.link";
@import "_base.section";
$inuit-heading-size-1:  36px !default; //line-height: 36px;
$inuit-heading-size-2:  30px !default; //line-height: 36px;
$inuit-heading-size-3:  24px !default; //line-height: 36px;
$inuit-heading-size-4:  20px !default; //line-height: 36px;
$inuit-heading-size-5:  16px !default; //line-height: 25px;
$inuit-heading-size-6:  14px !default; //line-height: 17px;
@import "../../../bower_components/inuit-headings/_base.headings";
@import "_base.label";
@import "../../../bower_components/inuit-list-bare/_objects.list-bare";
@import "../../../bower_components/inuit-list-inline/_objects.list-inline";
@import "../../../bower_components/inuit-images/base.images";
@import "_base.images";

/**
 * #OBJECTS
 */
@import "_objects.wrapper";
$inuit-enable-btn--small:   true;
$inuit-enable-btn--large:   true;
$inuit-enable-btn--full:    true;
$inuit-btn-radius:          $brand-round;
@import "../../../bower_components/inuit-buttons/_objects.buttons";
@import "_objects.buttons";
@import "_objects.form-form-grid";
@import "_objects.form-form-group";
@import "_objects.form-input-group";
@import "_objects.form-select-group";
@import "_objects.form-label";
@import "_objects.form-text-input";
@import "_objects.form-checkbox";
@import "_objects.tables";
@import "../../../bower_components/inuit-flag/_objects.flag";
@import "_objects.picker";

/**
 * #COMPONENTS
 */
@import "_components.buttons";
@import "_components.forms";
@import "_components.icons";

@import "_components.frames";
@import "_components.modals";
@import "_components.nav-tabs";
@import "_components.slidepanel";
@import "_components.fixed";
@import "_components.datepicker";
@import "_components.tooltips";
@import "_components.loading";
@import "_components.jquery-ui";

@import "_components.page-head";
@import "_components.page-foot";
@import "_components.nav-top";
@import "_components.nav-main";
@import "_components.nav-foot";

@import "_components.promo-header";
@import "_components.promo-banner";
@import "_components.promo-box";
@import "_components.promo-flight";

@import "_components.offers";

@import "_components.search";
@import "_components.search-flight";
@import "_components.search-hotel";
@import "_components.search-car";
@import "_components.search-package";
@import "_components.dispo-package";
@import "_components.search-documentation";
@import "_components.search-insurance";

@import "_components.dispo-shared";
@import "_components.dispo-flight";
@import "_components.dispo-hotel";
@import "_components.dispo-calendar";
@import "_components.dispo-resume-search";
@import "_components.dispo-insurance";

@import "_components.detail-shared";
@import "_components.detail-flight";
@import "_components.detail-hotel";
@import "components.detail-info-hotel";
@import "_components.detail-multi";

@import "_components.resume-shared";
@import "_components.resume-flight";

@import "_components.corporate";
@import "_components.bundle-content";
@import "_components.bundle-customer";

@import "_components.services";

/**
 * #TRUMPS
 */
@import "_trumps.types";
@import "../../../bower_components/inuit-print/_trumps.print";
@import "_trumps.print";
@import "_trumps.cursors";
@import "../../../bower_components/inuit-spacing/_trumps.spacing";
@import "_trumps.float";
@import "_trumps.show-hide";
@import "_trumps.spacing";
@import "_trumps.margin-padding";
@import "_trumps.accessibility";
@import "_trumps.responsive-js";
                
/**
*#OCTOPUS
*/
@import "_style_octopus";
                
                
                
                