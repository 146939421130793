/*------------------------------------*\
    #SELECT-GROUP
\*------------------------------------*/

select {
    position : relative;
    appearance: none;
}

.select--arrow {
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGcgaWQ9ImFycm93X3g1Rl9kb3duIj48cG9seWdvbiBmaWxsPSIjYmJiYmJiIiBwb2ludHM9IjUxMS44NiwxMjguMDA4IDI1NiwzODMuODUyIDAuMTQsMTI4LjAwOCIvPjwvZz48ZyBpZD0iQ2FwYV8xIj48L2c+PC9zdmc+)
        no-repeat calc(100% - 9px) calc(50% + 2px);
    background-size: 17px;
    background-color: white;
}
.select--arrow--hide-palm {
    @include media-query(palm) {
        background: none;
    }
}

// For IE
select::-ms-expand {
    display: none;
}

.select-group {
    position: relative;
    overflow: hidden;
}

.select-group select {
    padding-left: 0;
    width: calc(100% + 18px);
}