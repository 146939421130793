///*------------------------------------*\
//    #NAV-FOOT
//\*------------------------------------*/

.nav-foot__download {
    @include span(1 of 6);
    @include media-query(palm) {
        @include span(1 of 3);
    }
}
.nav-foot__compagny {
    @include span(1 of 9);
    @include media-query(lap) {
        @include span(1 of 6);
    }
    @include media-query(palm) {
        @include span(1 of 5);
    }
}
.nav-foot-dwl {
    background-image: url("/assets/common_assets/img/promo/download/download.jpg");
    background-repeat: no-repeat;
    background-size: 600%;
    background-position: 0 0;
    padding-top: 51%;
    transition: all 0.3s ease;
}
.nav-foot-dwl:hover{
    background-image: url("/assets/common_assets/img/promo/download/download_hover.jpg");
}
.nav-foot-comp {
background-image: url("/assets/octopus_assets/img/promo/compagny.jpg");
    background-repeat: no-repeat;
    background-size: 900%;
    background-position: 0 0;
    padding-top: 71%;
    transition: all 0.3s ease;
}
.nav-foot-comp:hover {
background-image: url("/assets/octopus_assets/img/promo/compagny_hover.jpg");
} 