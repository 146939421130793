///*------------------------------------*\
//    #AVAILABILITY CALENDAR
//\*------------------------------------*/

/*$colorCal-best-price:       #007bc5;*/
/*$colorCal-best-price:       darken($color-dispo, 10%);*/
$colorCal-heading:          #f37100;
$colorCal-best-price:       #00cc5f;
$colorCal-date-active:      #e6f5ff;

/* Wrappers */

.dispo__flight--calendar {
    width: 100%;
    @include media-query(palm) {
        padding: 0;
    }
}
.dispo-calendar__toogle-btn {
    display: inline-block;
    float: right;
    margin-bottom: $inuit-base-spacing-unit;
}
.dispo-calendar__container {
    margin-bottom: $inuit-base-spacing-unit;
}
.dispo-calendar__widget {
    text-align: right;
}
.dispo-calendar__table-wrapper {
    min-height: 150px;
}

.dispo-calendar__description {
    text-align: center;
    font-weight: 600;
    color: rgba(0,0,0,.6);
}

/* Table */

.dispo-calendar__table {
    width: 100%;
    border-radius: $base-round;
    table-layout: fixed;
    & th, & td {
        text-align: center;
    }
    // table stuff
    & .row0.col0,
    & .row0.col1,
    & .row0.col1 .cal-heading-wrapper,
    & .row1.col0,
    & .row1.col0 .cal-heading-wrapper {
        border-top-left-radius: $base-round;
    }
    & tr:first-of-type th:last-of-type,
    & tr:first-of-type th:last-of-type .cal-heading-wrapper {
        border-top-right-radius: $base-round;
    }
    & tr:last-of-type td:last-of-type,
    & tr:last-of-type td:last-of-type .option-price {
        border-bottom-right-radius: $base-round;
    }
    & tr:last-of-type th:first-of-type,
    & tr:last-of-type th:first-of-type .cal-heading-wrapper {
        border-bottom-left-radius: $base-round;
    }
    & tr:not(:first-of-type) th,
    & tr:not(:first-of-type) td {
        // hard code height for inner wrappers relative dimensions, and cross-browser compatibility's sake (webkit would accept 0, while gecko 100%...)
        height: $inuit-base-spacing-unit*2 + $inuit-base-line-height + 2px;
    }
}

/* Elements */

.calendar__heading.row-head,
.calendar__heading.column-head {
    color: #fff;
    background-color: $colorCal-heading;
}
.calendar__heading.row-head {
    border-left: 2px solid rgba(255,255,255,.1);
    &.col1 {
        border-left: 0;
    }
}
.calendar__heading.column-head {
    border-bottom: 2px solid rgba(255,255,255,.1);
}

.calendar__heading .cal-heading-wrapper {
    height: 100%;
    position: relative;
    overflow: hidden;
}
.calendar__heading .inner-wrap {
    /* nesting, works... */
    height: 100%;
    position: relative;
}
.calendar__heading.row-head .inner-wrap {
    padding: 23px $inuit-base-spacing-unit--small $inuit-base-spacing-unit--small;
}
.calendar__heading.column-head .inner-wrap {
    padding: $inuit-base-spacing-unit--small;
}
.cal-heading-title {
    display:none;
    position: absolute;
    width: 100%;
    font-size: 11px;
    background-color: rgba(0,0,0,.3);
}
.calendar__heading.column-head .cal-heading-title {
    top: 42%;
    left: -43%;    
    transform: rotate(-90deg);
}
.calendar__heading.is-active .cal-heading-title {
    display: block;
    &:after {
        border-top: 8px solid rgba(0,0,0,.3);
        border-right: 8px solid transparent;
        border-left: 8px solid transparent;
        content: '';
        left: 50%;
        margin-left: -8px;
        position: absolute;
        top: 12px;
        width: 0;
        height: 0;
    }
}

.calendar__heading .cal-month {
    display: block;
    color: rgba(255,255,255,.5);
}
.calendar__heading.first-cell {
    width: $inuit-base-spacing-unit*5;
}

.calendar__cell {
    height: 0;
    cursor: pointer;
    background-color: #fff;
    &:not(.col7),
    &.col7 .option-price {
        border-right: 2px solid #eee;
    }
    tr:not(:last-of-type) &,
    tr:last-of-type & .option-price {
        border-bottom: 2px solid #eee;
    }
    @include attention() {
        background-color: darken($colorCal-date-active, 6%) !important;
        tr:not(:last-of-type) &,
        tr:last-of-type & .option-price {
            border-bottom-color: darken($colorCal-date-active, 18%);
        }
        & a.option-price {
            color: darken($color-dispo, 10%) !important;
        }
    }
}
.calendar__cell .option-price {
    display: block;
    line-height: $inuit-base-line-height;
    padding: $inuit-base-spacing-unit 0;
    font-weight: 500;
    transition: all 0s;
}

/* Elements states */

// current day being hovered
.calendar__heading.is-active {
    background-color: darken($color-dispo, 4%);
}
.calendar__cell.is-active {
    background-color: $colorCal-date-active;
}

// selected date
.calendar__cell.selected-date {
    background-color: #eee;
}

.calendar__cell.price-highlight a.option-price {
    color: #fff;
}

// best price
.calendar__cell.best-price {
    background-color: $colorCal-best-price;
    & a.option-price {
        font-weight: 600;
    }
}

.calendar__cell.alt-price {
    background-color: rgba($colorCal-best-price, .8);
}
.calendar__cell.alt-price--2 {
    background-color: rgba($colorCal-best-price, .6);
}
.calendar__cell.alt-price--3 {
    background-color: rgba($colorCal-best-price, .4);
}

// disabled
.calendar__cell.disabled {
    background-color: #f9f9f9 !important;
    border-bottom-color: #eee !important;
    cursor: default;
    & .option-price {
        height: 100%;
        padding: 0;
    }
    tr:last-of-type & .option-price {
        border-bottom-color: #eee !important;
    }
}

.dispo-calendar__nav {
    position: absolute;
    top: 0;
    padding: $inuit-base-spacing-unit--small;
    margin: $inuit-base-spacing-unit--small;
    font-size: 1.1em;
    @include font-weight(bold);
    z-index: $z-index-datepicker-arrow;
    @include media-query(lap-and-up) {
        display: none;
    }
    &.calendar-prev {
        left: 0;
    }
    &.calendar-next {
        right: 1px;
    }
}

.dispo-calendar__table {
    @include media-query(palm) {
        tr th.inactive,
        tr td.inactive {
            display: none;
        }
    }
    @include media-query(portable) {
        tr th:not(.col0),
        tr td:not(.col0) {
            font-size: 13px;
        }
    }
}