///*------------------------------------*\
//    #SEARCH PACKAGE
//\*------------------------------------*/

.search__col-package {
    @include span(1 of 3);
    @include media-query(portable) { 
        @include span(full);
    }
}

 