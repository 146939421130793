///*------------------------------------*\
//    #DISPO-PACKAGE
//\*------------------------------------*/

// Internal variable
$height-big:     240px;
$height-small:   140px;

.dispo-package__col-image {
    @include span(1 of 3);
    @include media-query(palm) {
        width: 100%;
    }
}
.dispo-package__image {
    width: 100%;
    height: $height-big + 45px;
    background: center center no-repeat;
    background-size: cover;
    border-radius: 10px;
}

.dispo-package__col-content {
    color: $color-gree-text;
    @include span(2 of 3);
    @include media-query(palm) {
        width: 100%;
        margin-top: 10px;
    }
}
.dispo-package__title {
    line-height: 1.4;
}
.dispo-package__detail {
    @include font-size(16px);
    @include media-query(desk){
        width: 100%;
    }
}
.dispo-package__price {
    // @include font-size(20px);
    @include media-query (desk){
        position: absolute;
        bottom: 10px;
    }
}

.dispo-package__cautions {
    @include span(full);
    display: none;
}    
.dispo-package__cautions-content {
    padding: $inuit-base-spacing-unit--small $inuit-base-spacing-unit--small 0;
    margin-bottom: $inuit-base-spacing-unit--small;
    background-color: $gray-plain-light;
    box-shadow: 2px 2px #eee;
}
.dispo-package__cautions-loader {
    min-height: 200px;
}
.dispo-package_ribbon {
    position: absolute;
    right: -4px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;
}
.dispo-package_ribbon span {
    font-size: 12px;
    font-weight: bold;
    color: #FFF;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 100px;
    display: block;
    background: #79A70A;
    background: linear-gradient(#1e5799 0%, #34bac9 57%);  
    position: absolute;
    top: 19px;
    right: -21px;
}
.dispo-package_ribbon span::before {
    content: "";
    position: absolute; left: 0px; top: 100%;
    z-index: -1;
    border-left: 3px solid #34bac9;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #34bac9;
}
.dispo-package_ribbon span::after {
    content: "";
    position: absolute; right: 0px; top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid #34bac9;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #34bac9;
}

#resumeSearchPackage{
    border: none;
}
.result-packet{
    font-size: 1.5em;
    margin: auto;
    color: $color-gree-text;
    @include media-query(desk){
        margin-left: 50px;
        font-size: 1.8em;
        padding: 9px;
    }
}
.search-right-package{
    margin-right: 50px !important;
    width: 17em;
    text-align: right;
    display: table-column;
}
.text-search-package {
    display: table-cell;
    width: 10em;
    font-size: 1em;
    font-weight: normal;
    color: $color-gree-text;
}
.search-price-right-package{
    display: table-cell;
    width: 10em;
    text-align: left;
    color: $color-gree-text;
    .icon{
        display: inline-block !important,
    }
}
.price-filters-package{
    width: 270px !important;
    right:68px !important;
    @include media-query(lap-and-up){
        width: 400px !important;
    }
}
.resume-search__block__title_package{
    position: relative;
    float: right;
    margin-left: 14px;
}
@include media-query (lap-and-up){
    .package-result{
        display: flex;
        justify-content: center;
    }
    .nav--light{
        display: none;
    }
    .dispo-package__container{
        min-height: 280px;
    }
    .js-packageForm{
        background-color: transparent !important;
        border: none !important;
    }
    .dispo-package__container .dispo-package__col-image{
        padding: 0 !important;
    }
    .search-submit-pack{
        width: 100% !important;
        margin-top:5px;
    }
    .title-pack{
        margin-top: 50px;
    }
}
.dispo-package__title{
    @include font-weight(bold);
    color: $color-gree-text !important;
    line-height: inherit;
}
.vigencia-pack{
    list-style: none;
    text-align: end;
    font-weight: bold;
    @include media-query (palm){
        text-align: initial;
        margin-left: 0px;
    }
}
.dispo-package__duration{
    @include media-query(desk){
        float: right;
        position: absolute;
        bottom: 10px;
        right: 25px;
        padding-left: 8px;
    }
}
.dispo-pack-icon{
    margin-left: 4px;    
}
.dispo-pack{
    margin-bottom: 1px;
}
.dispo-pack-out{
    margin-right: 17px;
}
.prise-dispo{
    list-style: none;
    margin-left: 0 !important;  
    @include media-query(palm){
        position: absolute;
        margin-top: 8em;
    }
    @media screen and (min-width:722px) and (max-width:1023px) {
        position: absolute;
        bottom: 47px;
    }
}
.condensed{
    margin-bottom: 0px;
}
.js-packagePrice{
    font-size: 1.8em;
    color: $color-main;
    @include media-query(palm){
        font-size: 16px;        
        margin: 5px 0;
    }
}
.prise-result{
    font-size: 16px;
    margin-right: 4px;
}
@include media-query (desk){
    .slides-double-pack{
        left: -4px;
        margin: 50px 0 !important;
    }
}
@include media-query(palm){
    .day-pack{
        float: left;
        margin-right: 12px;
    }
    #searchInputValidate-tipoViaje{
        width: 95%;
        margin-left: 9px;
        margin-bottom: 19px;
    }
    .inicio-pack{
        float: left;
        border-right: 1px solid;
        padding-right: 10px;
        margin-right: 10px;
    }
    // .icon_search_calendar{
    //     display: none;
    // }
    .dispo-package__submit{
        margin-top: 7em;
    }
    .slides-double-pack{
        left: 50px;
    }
    .tittle-carousel{
        text-align: center;
    }
    .result-packet{
        margin-left: 23px;
    }
    .prise-detail{
        float: initial;
    }
}
.input_search_mobile{
    @include media-query(palm){
        width: 90% !important;
        float: left;
        padding-left: 9px;
        padding-right: 9px;
    }
}
    /*.search-pack-col-clave{
        @include media-query(lap-and-up){
        width: 33.3333%;
        float: left;
        padding-left: 9px;
        padding-right: 9px;
        }
    }*/

@include media-query(palm){
    #searchInputCities-input{
        width: 100% !important;
    }
    #searchInputValidate-tipoViaje{
        width: 95% !important;
    }
}
@media screen and (min-width:1024px) and (max-width:1150px){
    .dispo-package__price-container{
        margin-top: 6em;
    }
    .slides-double-pack{
        left: 33px !important;
    }
}

@media screen and (min-width:722px) and (max-width:1023px) {
    .dispo-package__submit{
        position: absolute;
        bottom: 5px;
        width: 62%;
    }   
    .vigencia-pack{
        margin-bottom: 60px;
    }
    .slides-double-pack{
        left: 60px;
    }
}
.price-package-detail{
    position: relative;
    bottom: 0px;
}
.vigenci-package-detail{
    position: relative;
    bottom: 0px;
    float: none;
    margin-left: 12px;
}
@include media-query(desk){
    .conten-package-availability{
    margin-top: 100px;
    }
}
.content-dispo-pack{
    @include span(8 of 8);
    @include media-query(portable) {
        @include span(full);
    }
    min-height: 400px;
}
.resume-pack-header{
    @include media-query(palm){
        float: none !important;
        text-align: center !important;
    }
}
.dispo-package-info{
    @include media-query(desk){
        width: 50%;
    }  
}
.dispo-detail-package{
    @include media-query(desk){
        border-left: 1px solid rgba(0, 0, 0, 0.12);
    }  
}