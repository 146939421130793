                                                                                                                                            ///*------------------------------------*\
//    #NAV-TOP
//\*------------------------------------*/

// Internal variables.
$logo-height:   85px;
$mobile-navbar-height:      $logo-height + (2*$inuit-base-spacing-unit);
$mobile-menu-img-height:    170px;

.page-head,
%page-head {
    position: relative;
    margin-bottom: $inuit-base-spacing-unit;
    // @include material-shadow(1);
    @include media-query(palm) {
        background-color: transparent;
    }
}
%page-head-bg-container {
    position: absolute;
    width: 100%;
    z-index: -1;
    overflow: hidden;
    // height: unset;
}.page-head__bg-container--responsive {
    @extend %page-head-bg-container;
    height: 88px ;
    background-color: $color-main;
    @include material-shadow(2);
}
.page-head__bg__img {
    &.bg-img_background_default     { background-image: url(../img/header/covers/snapshots/background_default.jpg); }
    &.bg-img_background_special       { background-image: url(../img/header/covers/snapshots/background_special.jpg); }
}
.page-head--responsive {
    @include media-query(palm) {
        margin-left: -$inuit-base-spacing-unit--small;
        width: calc(100% + #{$inuit-base-spacing-unit});
    }
}
.page-head__menu-mobile{
    margin-top: 5px;
}
.page-head__logo{
    @include media-query(lap-and-up) {
        margin-top: 0;
    }    
    @include media-query(desk) {
        width: 12em;
    }    
}
.page-head__aviatur {
    max-height: $logo-height;
    @include media-query(lap-and-up) {
        width: 100%;
        float: left;
        // width: 210px;
        padding-right: 15px;
        margin-top: 0px;
    }
    @include media-query(palm) {
        /* content: url("../img/custom/octopus-logo-blanco.svg"); */
        max-height: 85px;
        margin-left: 3px;
        width: 222px;
    }
}
.page-head__aviatur-pdf {
    max-height: 75px;
    padding: 5px 0;
}
.page-head__award {
    height: $logo-height;
    padding: $inuit-base-spacing-unit--small 0;
    margin-left: 20px;
}
// PROMO TIME !
.page-head--promo {
    background: url(../img/header/cover.jpg) no-repeat center center;
    background-size: cover;
}
.page-head--promo--luna {
    background: none;
    position: relative;
}
.page-head--promo * {
    color: $color-page-head-promo;
}
.resume__row-passenger-logo {
    width: 50%;
    float: left;
    padding-left: 9px;
    padding-right: 9px;
}
/*.hide-portable {margin-top: 50px !important;}*/
