///*------------------------------------*\
//    #PROMO-HEADER
//\*------------------------------------*/


.promo-photoHover-dwl {
    background-image: url("/assets/common_assets/img/promo/download/download.jpg");
    background-repeat: no-repeat;
    background-size: 600%;
    background-position: 0 0;
    padding-top: 51%;
    transition: all 0.3s ease;
}
.promo-photoHover-dwl:hover{
    background-image: url("/assets/common_assets/img/promo/download/download_hover.jpg");
}
.promo-photoHover-comp{
//    background-image: url("../img/promo/compagnies/compagny.jpg");
    background-repeat: no-repeat;
    background-size: 900%;
    background-position: 0 0;
    padding-top: 71%;
    transition: all 0.3s ease;
}
.promo-photoHover-comp:hover{
//    background-image: url("../img/promo/compagnies/compagny_hover.jpg");
}
// LUNA LLENA HEADER //---------------------------------------------------------
.luna {
    background: url(/assets/common_assets/img/promo/luna-llena/header/sky.jpg) no-repeat center center;
    background-size: cover;
    height: 100%;
    overflow: hidden;
    position: absolute;
    text-align: center;
    width: 100%;
    z-index: $z-index-luna;

}
.luna-cloud {
    position: absolute;
    width: 200%;  
}
.luna-cloudUnder { 
    background-color:#A1A6B9;
    bottom: 0;
    height: 25px;
    position: absolute;
    width: 100%;
}
.luna-cloud--moon {
    animation: scaleout100pc 200s infinite linear;
    background-size: contain;
    background:url(/assets/common_assets/img/promo/luna-llena/header/moon-0.png) no-repeat;
    left: 80%;
    bottom: 135px;
    height: 150px;
    width: 100%;
    opacity: 0.90;
    @include media-query(lap) {
        background-size: 150px;
        width: 150px;
        bottom: 100px;
    }
    @include media-query(palm) {
        background-size: 100px;
        bottom: 0px;
        height: 100px;
    }
}
.luna-cloud--1 {
    animation: scaleout300 30s infinite ease-in-out;
    background:url(/assets/common_assets/img/promo/luna-llena/header/fondofinal1.png) repeat-x;
    bottom: 25px;
    height: 170px;
    opacity: 0.95;
    @include media-query(lap) {
        bottom: 5px;
    }
    @include media-query(palm) {
        bottom: -80px;
    }
}
.luna-cloud--2 {
    animation: scaleout367 20s infinite ease-in-out;
    background:url(/assets/common_assets/img/promo/luna-llena/header/fondofinal2.png) repeat-x;
    bottom: 25px;
    height: 120px;
    opacity: 0.9;
    @include media-query(lap) {
        bottom: 10px;
    }
    @include media-query(palm) {
        bottom: -60px;
    }
}
.luna-cloud--3 {
    animation: scaleout300 15s infinite ease-in-out;
    background:url(/assets/common_assets/img/promo/luna-llena/header/fondofinal3.png) repeat-x;
    bottom: 25px;
    height: 100px;
    opacity: 0.9;
    @include media-query(lap) {
        bottom: 0px;
    }
    @include media-query(palm) {
        bottom: -50px;
    }
}
@-webkit-keyframes scaleout100pc {
    0%   { -webkit-transform: translateX(0); }
    100% { -webkit-transform: translateX(-100%); }
}
@keyframes scaleout100pc {
    0%   { transform: translateX(0); } 
    100% { transform: translateX(-100%); }
}
@-webkit-keyframes scaleout300 {
    0%   { -webkit-transform: translateX(0); }
    100% { -webkit-transform: translateX(-300px); }
}
@keyframes scaleout300 {
    0%   { transform: translateX(0); } 
    100% { transform: translateX(-300px); }
}
@-webkit-keyframes scaleout367 {
    0%   { -webkit-transform: translateX(0); }
    100% { -webkit-transform: translateX(-367px); }
}
@keyframes scaleout367 {
    0%   { transform: translateX(0); } 
    100% { transform: translateX(-367px); }
}
