                    /*------------------------------------*\
    #BUTTONS
\*------------------------------------*/

.btn {
    transition: 0.2s;
    @include font-weight(bold);
    @include btn-color($color-btn);
    box-shadow: 0 0 2px rgba(0,0,0,.08),0 2px 4px rgba(0,0,0,.16);
}
.btn--secondary {
    background: none;
    color: $color-btn;

    @include attention() {
        background: none;
        color: darken($color-btn, 10%);
    }
    @include attention(active) {
        background: $color-btn;
        color: white;
    }
}
.btn--accent {
    @include btn-color($color-btn-accent);
}
.btn--positive {
    @include font-weight(regular);
    @include btn-color($color-btn-positive);
}
.btn--negative {
    @include font-weight(regular);
    @include btn-color($color-btn-negative);
}
.btn--submit {
    @include font-weight(regular);
    @include btn-color($color-btn-submit);
}
.btn--inactive {
    @include font-weight(regular);
    @include btn-color(rgba(0,0,0,0), $gray-main-light); 
    @include btn-inactive($color-btn);
}


// Styling btn-group

.btn-group {
    border-radius: $base-round;
    @include media-query(lap-and-up) {
        box-shadow: transparent;
    }
}

.btn-group > .btn{
    box-shadow: none;
    @include attention() {
        box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
    }
}

//button styles for corporative policies
.btn--red {

    background-color: rgba(250, 30, 0, 0.9);

    border-color: rgba(250, 30, 0, 0.9);

    color: white;

}

.btn--red:hover {

    color: white;

    background-color: rgba(138, 8, 8, 0.9) !important;

    border-color: rgba(138, 8, 8, 0.9) !important;

}

.btn--red:focus {

    color: white;

}

.btn--nocolor {

    color: white;

    top: 10px;

    margin-top: 10px;

}

.btn--nocolor:hover {

    color: white;

    text-decoration: none !important;

}

.btn--nocolor:focus {

    color: white;

}

/***********verde*/

.btn--green {

    background-color: rgba(98, 171, 9, 0.9);

    border-color: rgba(98, 171, 9, 0.9);

    color: white;

}

.btn--green:hover {

    color: white;

    background-color: rgba(11, 97, 11, 0.9) !important;

    border-color: rgba(11, 97, 11, 0.9) !important;

}

.btn--green:focus {

    color: white;

}

                